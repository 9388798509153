<script setup lang="ts">
const runtimeConfig = useRuntimeConfig();
const staticLinks = useStaticLinks();
const route = useRoute();

const { t } = useI18n({
  useScope: 'local',
});

const fullUrl = computed(() => runtimeConfig.public.seo.siteUrl + route.fullPath);
</script>

<template>
  <div class="error-container">
    <h1>{{ t('heading') }}</h1>
    <h2>404</h2>
    <p>{{ t('first-paragraph') }}</p>
    <p>{{ t('second-paragraph') }}</p>
    <p>{{ t('third-paragraph') }}</p>
    <ul>
      <i18n-t tag="li" keypath="check-for-typos">
        <template #link>
          <br />
          <span class="italic">{{ fullUrl }}</span>
          <br />
        </template>
      </i18n-t>
      <i18n-t tag="li" keypath="use-search">
        <template #search>
          <nuxt-link to="/suche">{{ t('search') }}</nuxt-link>
        </template>
      </i18n-t>
      <i18n-t tag="li" keypath="back-to-home">
        <template #home>
          <nuxt-link to="/">{{ t('home') }}</nuxt-link>
        </template>
      </i18n-t>
    </ul>
    <i18n-t tag="p" keypath="contact-form-text">
      <template #form>
        <nuxt-link :to="staticLinks.kontakt">{{ t('form') }}</nuxt-link>
      </template>
    </i18n-t>
  </div>
</template>

<style scoped lang="scss">
@import '~/assets/styles/tw-variables.scss';

.error-container {
  text-align: left;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  padding: 0.5rem 1rem;

  h1,
  h2 {
    margin: 1.5rem 0 1rem;
    text-transform: uppercase;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  p {
    margin-bottom: 1rem;
  }

  ul {
    margin: 0 2.5em 1rem;

    li {
      list-style-type: disc;
    }
  }

  a {
    color: $primary;
    font-size: 1rem;
    text-decoration: underline;

    &:hover {
      color: #ae6700;
    }
  }
}

.italic {
  font-style: italic;
}
</style>

<i18n lang="json">
{
  "de": {
    "heading": "Fehler - Seite nicht vorhanden",
    "first-paragraph": "Da ist wohl etwas schiefgelaufen. Du hast etwas gesucht, was sich hier nicht befindet.",
    "second-paragraph": "Vielleicht ist die Seite einfach verschwunden? Möglicherweise haben wir sie gelöscht? Oder du hast dich vertippt? Oder die Seite war nie da?",
    "third-paragraph": "Fest steht: Hier ist nichts, was dir weiterhelfen könnte.",
    "check-for-typos": "Überprüfe die angegebene Adresse auf Tippfehler: {link}",
    "use-search": "verwende die {search}",
    "search": "Suche",
    "back-to-home": "... oder gehe einfach zurück auf die {home}",
    "home": "Startseite",
    "contact-form-text": "Und falls du dir sicher bist, dass ein Fehler unsererseits vorliegt, freuen wir uns über einen Hinweis an uns über unser {form}.",
    "form": "Kontaktformular"
  },
  "en": {
    "heading": "Error - Page not found",
    "first-paragraph": "It seems like something went wrong. You are looking for something that doesn't exist.",
    "second-paragraph": "Perhaps the page has simply disappeared? Maybe we deleted it? Or maybe you mistyped it? Or the page was never there?",
    "third-paragraph": "One thing is certain: There is nothing here that could help you.",
    "check-for-typos": "Check the address for typos: {link}",
    "use-search": "Use the {search}",
    "search": "Search",
    "back-to-home": "... or go back to the {home}",
    "home": "Home",
    "contact-form-text": "And if you are sure that an error of ours has occurred, we would like to send you a notice to our {form}.",
    "form": "contact form"
  }
}
</i18n>
