import urlEncodeFetchBody from '~/utils/url-encode-fetch-body';

/**
 * @see https://nuxt.com/docs/guide/recipes/custom-usefetch#custom-fetch
 */
export default defineNuxtPlugin((nuxtApp) => {
  const headers = useRequestHeaders(['cookie']);
  const config = useRuntimeConfig();

  if (!config.public.apiUrl) {
    throw createError({
      statusCode: 500,
      statusMessage: 'Server Configuration Error',
      message: 'Missing `runtimeConfig.apiUrl` configuration.',
    });
  }

  const request = $fetch.create({
    baseURL: config.public.apiUrl,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...headers,
    },
    async onRequest({ options }) {
      const method = options.method?.toUpperCase();
      options.credentials = 'include';
      if (['POST', 'PUT', 'PATCH'].includes(method || '') && options.body) {
        options.body = urlEncodeFetchBody(options.body);
      }
    },
    async onResponseError({ response }) {
      if (response.status === 401) {
        await useLogout();
        await nuxtApp.runWithContext(() => navigateTo('/login'));
      }
    },
  });

  return {
    provide: {
      request,
    },
  };
});
