// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (body: Record<string, any> | BodyInit | null | undefined) => {
  if (body instanceof URLSearchParams) {
    return body;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const encode = (key: string, value: any, parentKey?: string): [string, string][] => {
    const keyPath = parentKey ? `${parentKey}[${key}]` : key;

    if (value === null || value === undefined) {
      // Handle null or undefined values
      return [[keyPath, '']]; // Encode as an empty value (key=)
    }

    if (value instanceof Date) {
      // Handle Date objects
      return [[keyPath, value.toISOString()]]; // Convert to ISO 8601 string
    }

    if (value && typeof value === 'object' && !Array.isArray(value)) {
      // Recursively encode nested objects
      return Object.entries(value).flatMap(([nestedKey, nestedValue]) => encode(nestedKey, nestedValue, keyPath));
    }

    if (Array.isArray(value)) {
      // Handle arrays, including objects in arrays
      return value.flatMap((item, index) => {
        if (item === null || item === undefined) {
          // Handle null or undefined values in arrays
          return [[`${keyPath}[]`, '']]; // Encode as an empty value
        }
        if (item instanceof Date) {
          // Handle Date objects in arrays
          return [[`${keyPath}[]`, item.toISOString()]];
        }
        if (typeof item === 'object') {
          // Recursively encode objects in arrays
          return encode(String(index), item, keyPath);
        }
        // Encode primitive values in arrays
        return [[`${keyPath}[]`, String(item)]];
      });
    }

    // Encode primitive values
    return [[keyPath, String(value)]];
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params = Object.entries(body as Record<string, any>).flatMap(([key, value]) => encode(key, value));

  return new URLSearchParams(params);
};
