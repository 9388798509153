import axios from 'axios';
/**
 * This axios plugin should only be used in some cases where fetch
 * does not work properly and SSR is not needed for the request
 * e.g. file uploads.
 */
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  if (!config.public.apiUrl) {
    throw createError({
      statusCode: 500,
      statusMessage: 'Server Configuration Error',
      message: 'Missing `runtimeConfig.apiUrl` configuration.',
    });
  }

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (error.response.status === 401) {
        await useLogout();
        await nuxtApp.runWithContext(() => navigateTo('/login'));
      }
      return Promise.reject(error);
    }
  );

  const axiosRequest = axios.create({
    baseURL: config.public.apiUrl,
    headers: {
      withCredentials: true,
    },
  });

  return {
    provide: {
      axiosRequest,
    },
  };
});
