/**
 * A simple abstracting composable to determine if the user is on a device larger than mobile.
 * We aim for a mobile first approach, so, larger screens are to check against.
 * This abstraction exists so the underlying logic can be easily changed,
 * because the current implementation is forseen to be replaced at
 * some point.
 *
 * For css, there is the mixin `tw-is-desktop` that you could use like this:
 *
 * @include tw-is-desktop {
 *   ...
 * }
 *
 * that should behave exactly the same.
 * See 'assets/styles/tw-mixins.scss'
 *
 * @returns {boolean} isDesktop
 */
export default function () {
  const { $viewport } = useNuxtApp();
  return computed(() => $viewport.isGreaterOrEquals("lg"));
}
