<script setup lang="ts">
const props = defineProps<{
  content: string | Component;
  componentProps?: Record<string, any>;
}>();

const renderContent = () => {
  if (typeof props.content === "string") {
    return h("span", props.content);
  } else {
    return h(props.content, props.componentProps);
  }
};
</script>

<template>
  <component :is="renderContent()" />
</template>
