
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as faqGygUpKPaA0u57hP9eCeKpzuZdev2jKgM_45LbsMdrZ3kMMeta } from "/app/browser/pages/faq.vue?macro=true";
import { default as login17uV2wPOEZyhEIjAtEGQLKPao7INyUQLBJ_ygE2hAasMeta } from "/app/browser/pages/login.vue?macro=true";
import { default as logoutgytaIHAtYUq7iLGF3RRRZJjbLY_45_J5iVlMcuRbHNwqcMeta } from "/app/browser/pages/logout.vue?macro=true";
import { default as imprintD4OnFA3rlG_cQF18SpHLrFGPhaVv6XH3NLi2IK_gYG0Meta } from "/app/browser/pages/imprint.vue?macro=true";
import { default as privacyYjbSwhOfeGFaDBkfD1ZbohrL_7_gPbkN8XhV60v_lgoMeta } from "/app/browser/pages/privacy.vue?macro=true";
import { default as willkommenG4yy0w5s9aoyWwP91E8LbDoCrjn2F76is6jeYQD_RTwMeta } from "/app/browser/pages/willkommen.vue?macro=true";
import { default as editCc_45qhwP6ivMedxGYLKHsEjmX8FukwHE7GROYt_27P60Meta } from "/app/browser/pages/housing/edit.vue?macro=true";
import { default as registriereneMjt3_2sEx3IzURx3wRi_0Rsxmv_7KCVdYuJAMmSCCIMeta } from "/app/browser/pages/registrieren.vue?macro=true";
import { default as indexDQX9jYSGF0iAPEjGAJc08L2jrOC7yc_45a_45sqbmFnRLK0Meta } from "/app/browser/pages/account/index.vue?macro=true";
import { default as photosf5VuzJqBNAMXpY2AJen2ih2iad0_7mXc1Zuion8EJdQMeta } from "/app/browser/pages/housing/photos.vue?macro=true";
import { default as deletedmimRTKAZnsgxlh0dVBNMlWr8G_YEVv3a8BPxK97dPGUMeta } from "/app/browser/pages/account/deleted.vue?macro=true";
import { default as matcheslsGuV4YCLi36ugJDRrT6YFx2pjhkIRCeSullyWY91GIMeta } from "/app/browser/pages/dashboard/matches.vue?macro=true";
import { default as indexADLS3b8RH8hLzvXTle3pmCP3SF_OrR85O672uUNZr3sMeta } from "/app/browser/pages/account/settings/index.vue?macro=true";
import { default as forgot_45passwordEJniiqP63hl_45AqmQfjY9dSlrmASyyB66RhX0dUSnjHcMeta } from "/app/browser/pages/account/forgot-password.vue?macro=true";
import { default as privacyenR65Rmk_45_45nFDlPshP7kT1_CMUGFAAVMexbhs_su2YQMeta } from "/app/browser/pages/account/settings/privacy.vue?macro=true";
import { default as indexJtu3vPQ9qpaz_19cwl4gfR2Sh4XVMXhdOhPtHcZPfd0Meta } from "/app/browser/pages/account/subscription/index.vue?macro=true";
import { default as indexhWddQpGiZe7FVGb_pNElOI8rrXtGDen_cS8BGXI8AXYMeta } from "/app/browser/pages/account/ads-and-search/index.vue?macro=true";
import { default as credentialsd0xt0e0QcWAgXWu6Tv9v50PL8A_EeLmxL9qt1_45Dl3VwMeta } from "/app/browser/pages/account/settings/credentials.vue?macro=true";
import { default as multi_45portal_45publishinggxyfOSW2VW_6Zt17gw2iigzSkYbroOGg0jG8odYPw_45gMeta } from "/app/browser/pages/sale/multi-portal-publishing.vue?macro=true";
import { default as subscriptiond5N_45I7nKs54cmq_45jteh8H6GJxiEEKJIk7rKojJ2JdvoMeta } from "/app/browser/pages/account/settings/subscription.vue?macro=true";
import { default as notificationsPey2_45bfXetowK6nUpjh_V3umROEzqSwvY2TJiFr_45ihEMeta } from "/app/browser/pages/account/settings/notifications.vue?macro=true";
import { default as personal_45detailsm6bcGdXybgCLswO0Vt1ek3S_rb1eH6_4580j9KECxatx4Meta } from "/app/browser/pages/account/settings/personal-details.vue?macro=true";
import { default as _91confirmationCode_93buADoO3qPQopfiJoK5jogttPheRGD8541pEhm4rMGXUMeta } from "/app/browser/pages/confirm/password/[userId]/[confirmationCode].vue?macro=true";
import { default as _91_91intermediateUserGuid_93_93QLwJAziVCT3gUaiciKQOiNE6_3JSinXvfi_452_P_ZKC8Meta } from "/app/browser/pages/messaging/chat/[targetUserGuid]/[[intermediateUserGuid]].vue?macro=true";
import { default as offerHcQDUEOJnH_Vb_45X4xiidtFJ7sDcohLe9_jOa7bY4430Meta } from "/app/browser/components/pages/offer.vue?macro=true";
import { default as offer_45swap_45detailsU0_H4NtCsoxn0njy_45JfgJ4rYE9mjxt44cj7GhbOOggYMeta } from "/app/browser/components/pages/offer-swap-details.vue?macro=true";
import { default as searchOhV6bhNBGtFuoG54dLemrn4wOkTrzfOXNh5iBXyQhM8Meta } from "/app/browser/components/pages/search.vue?macro=true";
import { default as contactVl3ACi4i0Vuqe6LgLLf1w5ap_rFCOdFr8p_epV2vOl4Meta } from "/app/browser/components/pages/ring-swap/contact.vue?macro=true";
import { default as all_45chatsB0yU5IwIwt4QOAj_MjdEYXjL_uKCS4xM8R8Ulir7FBsMeta } from "/app/browser/components/pages/ring-swap/all-chats.vue?macro=true";
import { default as advanced_45navigationHbpQYivZok0quFpk3IGQR0RRCTQ9sVucp__455cmGmhiIMeta } from "/app/browser/components/pages/advanced-navigation.vue?macro=true";
import { default as _4043wHbUl924bj3AJ6OJaY58_ixjWkUHH7B5Uag9yZPXaQMeta } from "/app/browser/components/error/404.vue?macro=true";
import { default as _500OeY_2O2vvfkjYdhebOiQbcppk78I0u3lteV3J2qqhRIMeta } from "/app/browser/components/error/500.vue?macro=true";
import { default as pagesX2Thip1YHbi8FWGMnSmK9NUz2uUlVQ9QgID3qFIn_45aEMeta } from "/app/browser/modules/city-pages/pages.vue?macro=true";
export default [
  {
    name: "faq___de",
    path: "/faq",
    meta: faqGygUpKPaA0u57hP9eCeKpzuZdev2jKgM_45LbsMdrZ3kMMeta || {},
    component: () => import("/app/browser/pages/faq.vue")
  },
  {
    name: "login___de",
    path: "/login",
    meta: login17uV2wPOEZyhEIjAtEGQLKPao7INyUQLBJ_ygE2hAasMeta || {},
    component: () => import("/app/browser/pages/login.vue")
  },
  {
    name: "logout___de",
    path: "/logout",
    meta: logoutgytaIHAtYUq7iLGF3RRRZJjbLY_45_J5iVlMcuRbHNwqcMeta || {},
    component: () => import("/app/browser/pages/logout.vue")
  },
  {
    name: "imprint___de",
    path: "/imprint",
    component: () => import("/app/browser/pages/imprint.vue")
  },
  {
    name: "privacy___de",
    path: "/privacy",
    component: () => import("/app/browser/pages/privacy.vue")
  },
  {
    name: "willkommen___de",
    path: "/willkommen",
    meta: willkommenG4yy0w5s9aoyWwP91E8LbDoCrjn2F76is6jeYQD_RTwMeta || {},
    component: () => import("/app/browser/pages/willkommen.vue")
  },
  {
    name: "housing-edit___de",
    path: "/housing/edit",
    meta: editCc_45qhwP6ivMedxGYLKHsEjmX8FukwHE7GROYt_27P60Meta || {},
    component: () => import("/app/browser/pages/housing/edit.vue")
  },
  {
    name: "registrieren___de",
    path: "/registrieren",
    meta: registriereneMjt3_2sEx3IzURx3wRi_0Rsxmv_7KCVdYuJAMmSCCIMeta || {},
    component: () => import("/app/browser/pages/registrieren.vue")
  },
  {
    name: "account___de",
    path: "/account",
    meta: indexDQX9jYSGF0iAPEjGAJc08L2jrOC7yc_45a_45sqbmFnRLK0Meta || {},
    component: () => import("/app/browser/pages/account/index.vue")
  },
  {
    name: "housing-photos___de",
    path: "/housing/photos",
    meta: photosf5VuzJqBNAMXpY2AJen2ih2iad0_7mXc1Zuion8EJdQMeta || {},
    component: () => import("/app/browser/pages/housing/photos.vue")
  },
  {
    name: "account-deleted___de",
    path: "/account/deleted",
    component: () => import("/app/browser/pages/account/deleted.vue")
  },
  {
    name: "dashboard-matches___de",
    path: "/dashboard/matches",
    meta: matcheslsGuV4YCLi36ugJDRrT6YFx2pjhkIRCeSullyWY91GIMeta || {},
    component: () => import("/app/browser/pages/dashboard/matches.vue")
  },
  {
    name: "account-settings___de",
    path: "/account/settings",
    meta: indexADLS3b8RH8hLzvXTle3pmCP3SF_OrR85O672uUNZr3sMeta || {},
    component: () => import("/app/browser/pages/account/settings/index.vue")
  },
  {
    name: "account-forgot-password___de",
    path: "/account/forgot-password",
    component: () => import("/app/browser/pages/account/forgot-password.vue")
  },
  {
    name: "account-settings-privacy___de",
    path: "/account/settings/privacy",
    meta: privacyenR65Rmk_45_45nFDlPshP7kT1_CMUGFAAVMexbhs_su2YQMeta || {},
    component: () => import("/app/browser/pages/account/settings/privacy.vue")
  },
  {
    name: "account-subscription___de",
    path: "/account/subscription",
    meta: indexJtu3vPQ9qpaz_19cwl4gfR2Sh4XVMXhdOhPtHcZPfd0Meta || {},
    component: () => import("/app/browser/pages/account/subscription/index.vue")
  },
  {
    name: "account-ads-and-search___de",
    path: "/account/ads-and-search",
    meta: indexhWddQpGiZe7FVGb_pNElOI8rrXtGDen_cS8BGXI8AXYMeta || {},
    component: () => import("/app/browser/pages/account/ads-and-search/index.vue")
  },
  {
    name: "account-settings-credentials___de",
    path: "/account/settings/credentials",
    meta: credentialsd0xt0e0QcWAgXWu6Tv9v50PL8A_EeLmxL9qt1_45Dl3VwMeta || {},
    component: () => import("/app/browser/pages/account/settings/credentials.vue")
  },
  {
    name: "sale-multi-portal-publishing___de",
    path: "/sale/multi-portal-publishing",
    meta: multi_45portal_45publishinggxyfOSW2VW_6Zt17gw2iigzSkYbroOGg0jG8odYPw_45gMeta || {},
    component: () => import("/app/browser/pages/sale/multi-portal-publishing.vue")
  },
  {
    name: "account-settings-subscription___de",
    path: "/account/settings/subscription",
    meta: subscriptiond5N_45I7nKs54cmq_45jteh8H6GJxiEEKJIk7rKojJ2JdvoMeta || {},
    component: () => import("/app/browser/pages/account/settings/subscription.vue")
  },
  {
    name: "account-settings-notifications___de",
    path: "/account/settings/notifications",
    meta: notificationsPey2_45bfXetowK6nUpjh_V3umROEzqSwvY2TJiFr_45ihEMeta || {},
    component: () => import("/app/browser/pages/account/settings/notifications.vue")
  },
  {
    name: "account-settings-personal-details___de",
    path: "/account/settings/personal-details",
    meta: personal_45detailsm6bcGdXybgCLswO0Vt1ek3S_rb1eH6_4580j9KECxatx4Meta || {},
    component: () => import("/app/browser/pages/account/settings/personal-details.vue")
  },
  {
    name: "confirm-password-userId-confirmationCode___de",
    path: "/confirm/password/:userId()/:confirmationCode()",
    component: () => import("/app/browser/pages/confirm/password/[userId]/[confirmationCode].vue")
  },
  {
    name: "messaging-chat-targetUserGuid-intermediateUserGuid___de",
    path: "/messaging/chat/:targetUserGuid()/:intermediateUserGuid?",
    meta: _91_91intermediateUserGuid_93_93QLwJAziVCT3gUaiciKQOiNE6_3JSinXvfi_452_P_ZKC8Meta || {},
    component: () => import("/app/browser/pages/messaging/chat/[targetUserGuid]/[[intermediateUserGuid]].vue")
  },
  {
    name: "housing-show-wohnung-mieten___de",
    path: "/wohnung-mieten/:city/:area?/:slug",
    meta: { ...(offerHcQDUEOJnH_Vb_45X4xiidtFJ7sDcohLe9_jOa7bY4430Meta || {}), ...{"showMobileNavigation":true} },
    component: () => import("/app/browser/components/pages/offer.vue")
  },
  {
    name: "housing-show-wohnung-kaufen___de",
    path: "/wohnung-kaufen/:city/:area?/:slug",
    meta: {"showMobileNavigation":true},
    component: () => import("/app/browser/components/pages/offer.vue")
  },
  {
    name: "housing-show-wg-zimmer-mieten___de",
    path: "/wg-zimmer-mieten/:city/:area?/:slug",
    meta: {"showMobileNavigation":true},
    component: () => import("/app/browser/components/pages/offer.vue")
  },
  {
    name: "housing-show-haus-mieten___de",
    path: "/haus-mieten/:city/:area?/:slug",
    meta: {"showMobileNavigation":true},
    component: () => import("/app/browser/components/pages/offer.vue")
  },
  {
    name: "housing-show-haus-kaufen___de",
    path: "/haus-kaufen/:city/:area?/:slug",
    meta: {"showMobileNavigation":true},
    component: () => import("/app/browser/components/pages/offer.vue")
  },
  {
    name: "housing-show-swap-details-wohnung-mieten___de",
    path: "/wohnung-mieten/:city/:area?/:slug/tauschdetails",
    meta: {"middleware":["auth"],"showMobileNavigation":true},
    component: () => import("/app/browser/components/pages/offer-swap-details.vue")
  },
  {
    name: "housing-show-swap-details-wohnung-kaufen___de",
    path: "/wohnung-kaufen/:city/:area?/:slug/tauschdetails",
    meta: {"middleware":["auth"],"showMobileNavigation":true},
    component: () => import("/app/browser/components/pages/offer-swap-details.vue")
  },
  {
    name: "housing-show-swap-details-wg-zimmer-mieten___de",
    path: "/wg-zimmer-mieten/:city/:area?/:slug/tauschdetails",
    meta: {"middleware":["auth"],"showMobileNavigation":true},
    component: () => import("/app/browser/components/pages/offer-swap-details.vue")
  },
  {
    name: "housing-show-swap-details-haus-mieten___de",
    path: "/haus-mieten/:city/:area?/:slug/tauschdetails",
    meta: {"middleware":["auth"],"showMobileNavigation":true},
    component: () => import("/app/browser/components/pages/offer-swap-details.vue")
  },
  {
    name: "housing-show-swap-details-haus-kaufen___de",
    path: "/haus-kaufen/:city/:area?/:slug/tauschdetails",
    meta: {"middleware":["auth"],"showMobileNavigation":true},
    component: () => import("/app/browser/components/pages/offer-swap-details.vue")
  },
  {
    name: "housing-search-wohnung-mieten___de",
    path: "/suche/wohnung-mieten/:city?/:area?/:slug?",
    meta: {"showMobileNavigation":true},
    component: () => import("/app/browser/components/pages/search.vue")
  },
  {
    name: "housing-search-wohnung-kaufen___de",
    path: "/suche/wohnung-kaufen/:city?/:area?/:slug?",
    meta: {"showMobileNavigation":true},
    component: () => import("/app/browser/components/pages/search.vue")
  },
  {
    name: "housing-search-wg-zimmer-mieten___de",
    path: "/suche/wg-zimmer-mieten/:city?/:area?/:slug?",
    meta: {"showMobileNavigation":true},
    component: () => import("/app/browser/components/pages/search.vue")
  },
  {
    name: "housing-search-haus-mieten___de",
    path: "/suche/haus-mieten/:city?/:area?/:slug?",
    meta: {"showMobileNavigation":true},
    component: () => import("/app/browser/components/pages/search.vue")
  },
  {
    name: "housing-search-haus-kaufen___de",
    path: "/suche/haus-kaufen/:city?/:area?/:slug?",
    meta: {"showMobileNavigation":true},
    component: () => import("/app/browser/components/pages/search.vue")
  },
  {
    name: "suche___de",
    path: "/suche/:city?/:area?/:slug?",
    meta: {"showMobileNavigation":true},
    component: () => import("/app/browser/components/pages/search.vue")
  },
  {
    path: "/chat",
    name: "chat",
    redirect: "/messaging/dashboard"
  },
  {
    path: "/",
    name: "index",
    redirect: "/dashboard/matches"
  },
  {
    path: "/dashboard",
    name: "dashboard",
    redirect: "/account/ads-and-search"
  },
  {
    name: "privacy___de",
    path: "/datenschutz",
    meta: {"showMobileNavigation":true},
    component: () => import("/app/browser/pages/privacy.vue")
  },
  {
    name: "imprint___de",
    path: "/impressum",
    meta: {"showMobileNavigation":true},
    component: () => import("/app/browser/pages/imprint.vue")
  },
  {
    name: "ring-swap-contact___de",
    path: "/ring-swap/contact",
    meta: {"middleware":["auth"],"showMobileNavigation":false,"layout":"no-header-footer"},
    component: () => import("/app/browser/components/pages/ring-swap/contact.vue")
  },
  {
    name: "ring-swap-all-chats___de",
    path: "/ring-swap/all-chats",
    meta: {"middleware":["auth"],"showMobileNavigation":false,"layout":"no-header-footer"},
    component: () => import("/app/browser/components/pages/ring-swap/all-chats.vue")
  },
  {
    name: "messaging-chat___de",
    path: "/messaging/chat/:targetUserGuid?/:intermediateUserGuid?",
    component: () => import("/app/browser/pages/messaging/chat/[targetUserGuid]/[[intermediateUserGuid]].vue")
  },
  {
    name: "messaging-dashboard___de",
    path: "/messaging/dashboard",
    meta: {"middleware":["auth"],"showMobileNavigation":true},
    component: () => import("/app/browser/pages/messaging/chat/[targetUserGuid]/[[intermediateUserGuid]].vue")
  },
  {
    path: "/dashboard/liked",
    name: "dashboard-liked",
    redirect: "/dashboard/matches?status=watchlist"
  },
  {
    path: "/dashboard/interested",
    name: "dashboard-interested",
    redirect: "/dashboard/matches?status=otherFullInterest"
  },
  {
    name: "advanced-navigation___de",
    path: "/advanced/navigation",
    meta: {"middleware":["auth"],"showMobileNavigation":true},
    component: () => import("/app/browser/components/pages/advanced-navigation.vue")
  },
  {
    name: "custom-404___de",
    path: "/:pathMatch(.*)*",
    meta: {"showMobileNavigation":true},
    component: () => import("/app/browser/components/error/404.vue")
  },
  {
    name: "custom-500___de",
    path: "/500",
    meta: {"showMobileNavigation":true},
    component: () => import("/app/browser/components/error/500.vue")
  },
  {
    name: "berlin___de",
    path: "/berlin",
    meta: {"cityPage":{"name":"berlin","countryName":"germany","locationIds":{"regions":[],"cities":[3806],"areas":[]},"searchUrl":"/suche/wohnung-mieten/berlin?housingType=1&locationIds%5Bcities%5D=3806"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "hamburg___de",
    path: "/hamburg",
    meta: {"cityPage":{"name":"hamburg","countryName":"germany","locationIds":{"regions":[],"cities":[4703],"areas":[]},"searchUrl":"/suche/wohnung-mieten/hamburg?housingType=1&locationIds%5Bcities%5D=4703"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "muenchen___de",
    path: "/muenchen",
    meta: {"cityPage":{"name":"münchen","countryName":"germany","locationIds":{"regions":[],"cities":[2326],"areas":[]},"searchUrl":"/suche/wohnung-mieten/münchen?housingType=1&locationIds%5Bcities%5D=2326"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "münchen___de",
    path: "/m%C3%BCnchen",
    meta: {"cityPage":{"name":"münchen","countryName":"germany","locationIds":{"regions":[],"cities":[2326],"areas":[]},"searchUrl":"/suche/wohnung-mieten/münchen?housingType=1&locationIds%5Bcities%5D=2326"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "koeln___de",
    path: "/koeln",
    meta: {"cityPage":{"name":"köln","countryName":"germany","locationIds":{"regions":[],"cities":[8072],"areas":[]},"searchUrl":"/suche/wohnung-mieten/köln?housingType=1&locationIds%5Bcities%5D=8072"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "köln___de",
    path: "/k%C3%B6ln",
    meta: {"cityPage":{"name":"köln","countryName":"germany","locationIds":{"regions":[],"cities":[8072],"areas":[]},"searchUrl":"/suche/wohnung-mieten/köln?housingType=1&locationIds%5Bcities%5D=8072"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "frankfurt-am-main___de",
    path: "/frankfurt-am-main",
    meta: {"cityPage":{"name":"frankfurt am main","countryName":"germany","locationIds":{"regions":[],"cities":[4759],"areas":[]},"searchUrl":"/suche/wohnung-mieten/frankfurt-am-main?housingType=1&locationIds%5Bcities%5D=4759"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "stuttgart___de",
    path: "/stuttgart",
    meta: {"cityPage":{"name":"stuttgart","countryName":"germany","locationIds":{"regions":[],"cities":[1056],"areas":[]},"searchUrl":"/suche/wohnung-mieten/stuttgart?housingType=1&locationIds%5Bcities%5D=1056"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "duesseldorf___de",
    path: "/duesseldorf",
    meta: {"cityPage":{"name":"düsseldorf","countryName":"germany","locationIds":{"regions":[],"cities":[7960],"areas":[]},"searchUrl":"/suche/wohnung-mieten/düsseldorf?housingType=1&locationIds%5Bcities%5D=7960"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "düsseldorf___de",
    path: "/d%C3%BCsseldorf",
    meta: {"cityPage":{"name":"düsseldorf","countryName":"germany","locationIds":{"regions":[],"cities":[7960],"areas":[]},"searchUrl":"/suche/wohnung-mieten/düsseldorf?housingType=1&locationIds%5Bcities%5D=7960"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "leipzig___de",
    path: "/leipzig",
    meta: {"cityPage":{"name":"leipzig","countryName":"germany","locationIds":{"regions":[],"cities":[11685],"areas":[]},"searchUrl":"/suche/wohnung-mieten/leipzig?housingType=1&locationIds%5Bcities%5D=11685"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "dortmund___de",
    path: "/dortmund",
    meta: {"cityPage":{"name":"dortmund","countryName":"germany","locationIds":{"regions":[],"cities":[7806],"areas":[]},"searchUrl":"/suche/wohnung-mieten/dortmund?housingType=1&locationIds%5Bcities%5D=7806"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "essen___de",
    path: "/essen",
    meta: {"cityPage":{"name":"essen","countryName":"germany","locationIds":{"regions":[],"cities":[7961],"areas":[]},"searchUrl":"/suche/wohnung-mieten/essen?housingType=1&locationIds%5Bcities%5D=7961"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "bremen___de",
    path: "/bremen",
    meta: {"cityPage":{"name":"bremen","countryName":"germany","locationIds":{"regions":[],"cities":[4701],"areas":[]},"searchUrl":"/suche/wohnung-mieten/bremen?housingType=1&locationIds%5Bcities%5D=4701"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "dresden___de",
    path: "/dresden",
    meta: {"cityPage":{"name":"dresden","countryName":"germany","locationIds":{"regions":[],"cities":[11347],"areas":[]},"searchUrl":"/suche/wohnung-mieten/dresden?housingType=1&locationIds%5Bcities%5D=11347"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "hannover___de",
    path: "/hannover",
    meta: {"cityPage":{"name":"hannover","countryName":"germany","locationIds":{"regions":[],"cities":[7060],"areas":[]},"searchUrl":"/suche/wohnung-mieten/hannover?housingType=1&locationIds%5Bcities%5D=7060"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "nuernberg___de",
    path: "/nuernberg",
    meta: {"cityPage":{"name":"nürnberg","countryName":"germany","locationIds":{"regions":[],"cities":[1485],"areas":[]},"searchUrl":"/suche/wohnung-mieten/nürnberg?housingType=1&locationIds%5Bcities%5D=1485"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "nürnberg___de",
    path: "/n%C3%BCrnberg",
    meta: {"cityPage":{"name":"nürnberg","countryName":"germany","locationIds":{"regions":[],"cities":[1485],"areas":[]},"searchUrl":"/suche/wohnung-mieten/nürnberg?housingType=1&locationIds%5Bcities%5D=1485"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "duisburg___de",
    path: "/duisburg",
    meta: {"cityPage":{"name":"duisburg","countryName":"germany","locationIds":{"regions":[],"cities":[7959],"areas":[]},"searchUrl":"/suche/wohnung-mieten/duisburg?housingType=1&locationIds%5Bcities%5D=7959"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "bochum___de",
    path: "/bochum",
    meta: {"cityPage":{"name":"bochum","countryName":"germany","locationIds":{"regions":[],"cities":[7805],"areas":[]},"searchUrl":"/suche/wohnung-mieten/bochum?housingType=1&locationIds%5Bcities%5D=7805"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "wuppertal___de",
    path: "/wuppertal",
    meta: {"cityPage":{"name":"wuppertal","countryName":"germany","locationIds":{"regions":[],"cities":[8024],"areas":[]},"searchUrl":"/suche/wohnung-mieten/wuppertal?housingType=1&locationIds%5Bcities%5D=8024"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "bielefeld___de",
    path: "/bielefeld",
    meta: {"cityPage":{"name":"bielefeld","countryName":"germany","locationIds":{"regions":[],"cities":[7888],"areas":[]},"searchUrl":"/suche/wohnung-mieten/bielefeld?housingType=1&locationIds%5Bcities%5D=7888"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "bonn___de",
    path: "/bonn",
    meta: {"cityPage":{"name":"bonn","countryName":"germany","locationIds":{"regions":[],"cities":[8035],"areas":[]},"searchUrl":"/suche/wohnung-mieten/bonn?housingType=1&locationIds%5Bcities%5D=8035"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "muenster___de",
    path: "/muenster",
    meta: {"cityPage":{"name":"münster","countryName":"germany","locationIds":{"regions":[],"cities":[8154],"areas":[]},"searchUrl":"/suche/wohnung-mieten/münster?housingType=1&locationIds%5Bcities%5D=8154%2C32507"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "münster___de",
    path: "/m%C3%BCnster",
    meta: {"cityPage":{"name":"münster","countryName":"germany","locationIds":{"regions":[],"cities":[8154],"areas":[]},"searchUrl":"/suche/wohnung-mieten/münster?housingType=1&locationIds%5Bcities%5D=8154%2C32507"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "karlsruhe___de",
    path: "/karlsruhe",
    meta: {"cityPage":{"name":"karlsruhe","countryName":"germany","locationIds":{"regions":[],"cities":[409],"areas":[]},"searchUrl":"/suche/wohnung-mieten/karlsruhe?housingType=1&locationIds%5Bcities%5D=409"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "mannheim___de",
    path: "/mannheim",
    meta: {"cityPage":{"name":"mannheim","countryName":"germany","locationIds":{"regions":[],"cities":[433],"areas":[]},"searchUrl":"/suche/wohnung-mieten/mannheim?housingType=1&locationIds%5Bcities%5D=433"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "augsburg___de",
    path: "/augsburg",
    meta: {"cityPage":{"name":"augsburg","countryName":"germany","locationIds":{"regions":[],"cities":[3140],"areas":[]},"searchUrl":"/suche/wohnung-mieten/augsburg?housingType=1&locationIds%5Bcities%5D=3140"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "wiesbaden___de",
    path: "/wiesbaden",
    meta: {"cityPage":{"name":"wiesbaden","countryName":"germany","locationIds":{"regions":[],"cities":[4927],"areas":[]},"searchUrl":"/suche/wohnung-mieten/wiesbaden?housingType=1&locationIds%5Bcities%5D=4927"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "gelsenkirchen___de",
    path: "/gelsenkirchen",
    meta: {"cityPage":{"name":"gelsenkirchen","countryName":"germany","locationIds":{"regions":[],"cities":[8153],"areas":[]},"searchUrl":"/suche/wohnung-mieten/gelsenkirchen?housingType=1&locationIds%5Bcities%5D=8153"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "braunschweig___de",
    path: "/braunschweig",
    meta: {"cityPage":{"name":"braunschweig","countryName":"germany","locationIds":{"regions":[],"cities":[6673],"areas":[]},"searchUrl":"/suche/wohnung-mieten/braunschweig?housingType=1&locationIds%5Bcities%5D=6673"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "kiel___de",
    path: "/kiel",
    meta: {"cityPage":{"name":"kiel","countryName":"germany","locationIds":{"regions":[],"cities":[13263],"areas":[]},"searchUrl":"/suche/wohnung-mieten/kiel?housingType=1&locationIds%5Bcities%5D=13263"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "chemnitz___de",
    path: "/chemnitz",
    meta: {"cityPage":{"name":"chemnitz","countryName":"germany","locationIds":{"regions":[],"cities":[10943],"areas":[]},"searchUrl":"/suche/wohnung-mieten/chemnitz?housingType=1&locationIds%5Bcities%5D=10943"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "rostock___de",
    path: "/rostock",
    meta: {"cityPage":{"name":"rostock","countryName":"germany","locationIds":{"regions":[],"cities":[6358],"areas":[]},"searchUrl":"/suche/wohnung-mieten/rostock?housingType=1&locationIds%5Bcities%5D=6358"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "freiburg-im-breisgau___de",
    path: "/freiburg-im-breisgau",
    meta: {"cityPage":{"name":"freiburg im breisgau","countryName":"germany","locationIds":{"regions":[],"cities":[76],"areas":[]},"searchUrl":"/suche/wohnung-mieten/freiburg-im-breisgau?housingType=1&locationIds%5Bcities%5D=76"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "magdeburg___de",
    path: "/magdeburg",
    meta: {"cityPage":{"name":"magdeburg","countryName":"germany","locationIds":{"regions":[],"cities":[12465],"areas":[]},"searchUrl":"/suche/wohnung-mieten/magdeburg?housingType=1&locationIds%5Bcities%5D=12465"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "luebeck___de",
    path: "/luebeck",
    meta: {"cityPage":{"name":"lübeck","countryName":"germany","locationIds":{"regions":[],"cities":[13265],"areas":[]},"searchUrl":"/suche/wohnung-mieten/lübeck?housingType=1&locationIds%5Bcities%5D=13265"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "lübeck___de",
    path: "/l%C3%BCbeck",
    meta: {"cityPage":{"name":"lübeck","countryName":"germany","locationIds":{"regions":[],"cities":[13265],"areas":[]},"searchUrl":"/suche/wohnung-mieten/lübeck?housingType=1&locationIds%5Bcities%5D=13265"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "oldenburg___de",
    path: "/oldenburg",
    meta: {"cityPage":{"name":"oldenburg","countryName":"germany","locationIds":{"regions":[],"cities":[7726],"areas":[]},"searchUrl":"/suche/wohnung-mieten/oldenburg?housingType=1&locationIds%5Bcities%5D=7726"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "heidelberg___de",
    path: "/heidelberg",
    meta: {"cityPage":{"name":"heidelberg","countryName":"germany","locationIds":{"regions":[],"cities":[393],"areas":[]},"searchUrl":"/suche/wohnung-mieten/heidelberg?housingType=1&locationIds%5Bcities%5D=393"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "mainz___de",
    path: "/mainz",
    meta: {"cityPage":{"name":"mainz","countryName":"germany","locationIds":{"regions":[],"cities":[9970],"areas":[]},"searchUrl":"/suche/wohnung-mieten/mainz?housingType=1&locationIds%5Bcities%5D=9970"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "ingolstadt___de",
    path: "/ingolstadt",
    meta: {"cityPage":{"name":"ingolstadt","countryName":"germany","locationIds":{"regions":[],"cities":[2173],"areas":[]},"searchUrl":"/suche/wohnung-mieten/ingolstadt?housingType=1&locationIds%5Bcities%5D=2173"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "erfurt___de",
    path: "/erfurt",
    meta: {"cityPage":{"name":"erfurt","countryName":"germany","locationIds":{"regions":[],"cities":[14466],"areas":[]},"searchUrl":"/suche/wohnung-mieten/erfurt?housingType=1&locationIds%5Bcities%5D=14466"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "regensburg___de",
    path: "/regensburg",
    meta: {"cityPage":{"name":"regensburg","countryName":"germany","locationIds":{"regions":[],"cities":[3035],"areas":[]},"searchUrl":"/suche/wohnung-mieten/regensburg?housingType=1&locationIds%5Bcities%5D=3035"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "potsdam___de",
    path: "/potsdam",
    meta: {"cityPage":{"name":"potsdam","countryName":"germany","locationIds":{"regions":[],"cities":[4345],"areas":[]},"searchUrl":"/suche/wohnung-mieten/potsdam?housingType=1&locationIds%5Bcities%5D=4345"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "aachen___de",
    path: "/aachen",
    meta: {"cityPage":{"name":"aachen","countryName":"germany","locationIds":{"regions":[],"cities":[8025],"areas":[]},"searchUrl":"/suche/wohnung-mieten/aachen?housingType=1&locationIds%5Bcities%5D=8025"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "salzgitter___de",
    path: "/salzgitter",
    meta: {"cityPage":{"name":"salzgitter","countryName":"germany","locationIds":{"regions":[],"cities":[6831],"areas":[]},"searchUrl":"/suche/wohnung-mieten/salzgitter?housingType=1&locationIds%5Bcities%5D=6831"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "darmstadt___de",
    path: "/darmstadt",
    meta: {"cityPage":{"name":"darmstadt","countryName":"germany","locationIds":{"regions":[],"cities":[4732],"areas":[]},"searchUrl":"/suche/wohnung-mieten/darmstadt?housingType=1&locationIds%5Bcities%5D=4732"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "erlangen___de",
    path: "/erlangen",
    meta: {"cityPage":{"name":"erlangen","countryName":"germany","locationIds":{"regions":[],"cities":[1401],"areas":[]},"searchUrl":"/suche/wohnung-mieten/erlangen?housingType=1&locationIds%5Bcities%5D=1401"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "ulm___de",
    path: "/ulm",
    meta: {"cityPage":{"name":"ulm","countryName":"germany","locationIds":{"regions":[],"cities":[1313],"areas":[]},"searchUrl":"/suche/wohnung-mieten/ulm?housingType=1&locationIds%5Bcities%5D=1313"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "offenbach-am-main___de",
    path: "/offenbach-am-main",
    meta: {"cityPage":{"name":"offenbach am main","countryName":"germany","locationIds":{"regions":[],"cities":[4872],"areas":[]},"searchUrl":"/suche/wohnung-mieten/offenbach-am-main?housingType=1&locationIds%5Bcities%5D=4872"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "bamberg___de",
    path: "/bamberg",
    meta: {"cityPage":{"name":"bamberg","countryName":"germany","locationIds":{"regions":[],"cities":[2636],"areas":[]},"searchUrl":"/suche/wohnung-mieten/bamberg?housingType=1&locationIds%5Bcities%5D=2636"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "heilbronn___de",
    path: "/heilbronn",
    meta: {"cityPage":{"name":"heilbronn","countryName":"germany","locationIds":{"regions":[],"cities":[725],"areas":[]},"searchUrl":"/suche/wohnung-mieten/heilbronn?housingType=1&locationIds%5Bcities%5D=725"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "osnabrueck___de",
    path: "/osnabrueck",
    meta: {"cityPage":{"name":"osnabrück","countryName":"germany","locationIds":{"regions":[],"cities":[7761],"areas":[]},"searchUrl":"/suche/wohnung-mieten/osnabrück?housingType=1&locationIds%5Bcities%5D=7761"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "osnabrück___de",
    path: "/osnabr%C3%BCck",
    meta: {"cityPage":{"name":"osnabrück","countryName":"germany","locationIds":{"regions":[],"cities":[7761],"areas":[]},"searchUrl":"/suche/wohnung-mieten/osnabrück?housingType=1&locationIds%5Bcities%5D=7761"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "trier___de",
    path: "/trier",
    meta: {"cityPage":{"name":"trier","countryName":"germany","locationIds":{"regions":[],"cities":[10668],"areas":[]},"searchUrl":"/suche/wohnung-mieten/trier?housingType=1&locationIds%5Bcities%5D=10668"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "wolfsburg___de",
    path: "/wolfsburg",
    meta: {"cityPage":{"name":"wolfsburg","countryName":"germany","locationIds":{"regions":[],"cities":[6873],"areas":[]},"searchUrl":"/suche/wohnung-mieten/wolfsburg?housingType=1&locationIds%5Bcities%5D=6873"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "wien___de",
    path: "/wien",
    meta: {"cityPage":{"name":"wien","countryName":"austria","locationIds":{"regions":[],"cities":[38113],"areas":[]},"searchUrl":"/suche/wohnung-mieten/wien?housingType=1&locationIds%5Bcities%5D=38113"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "graz___de",
    path: "/graz",
    meta: {"cityPage":{"name":"graz","countryName":"austria","locationIds":{"regions":[],"cities":[38115],"areas":[]},"searchUrl":"/suche/wohnung-mieten/graz?housingType=1&locationIds%5Bcities%5D=38115"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "linz___de",
    path: "/linz",
    meta: {"cityPage":{"name":"linz","countryName":"austria","locationIds":{"regions":[],"cities":[25214],"areas":[]},"searchUrl":"/suche/wohnung-mieten/linz?housingType=1&locationIds%5Bcities%5D=25214"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "salzburg___de",
    path: "/salzburg",
    meta: {"cityPage":{"name":"salzburg","countryName":"austria","locationIds":{"regions":[],"cities":[30847],"areas":[]},"searchUrl":"/suche/wohnung-mieten/salzburg?housingType=1&locationIds%5Bcities%5D=30847"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "innsbruck___de",
    path: "/innsbruck",
    meta: {"cityPage":{"name":"innsbruck","countryName":"austria","locationIds":{"regions":[],"cities":[32374],"areas":[]},"searchUrl":"/suche/wohnung-mieten/innsbruck?housingType=1&locationIds%5Bcities%5D=32374"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "klagenfurt___de",
    path: "/klagenfurt",
    meta: {"cityPage":{"name":"klagenfurt","countryName":"austria","locationIds":{"regions":[],"cities":[38114],"areas":[]},"searchUrl":"/suche/wohnung-mieten/klagenfurt?housingType=1&locationIds%5Bcities%5D=38114"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "wels___de",
    path: "/wels",
    meta: {"cityPage":{"name":"wels","countryName":"austria","locationIds":{"regions":[],"cities":[27465],"areas":[]},"searchUrl":"/suche/wohnung-mieten/wels?housingType=1&locationIds%5Bcities%5D=27465"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "zuerich___de",
    path: "/zuerich",
    meta: {"cityPage":{"name":"zürich","countryName":"switzerland","locationIds":{"regions":[],"cities":[15526],"areas":[]},"searchUrl":"/suche/wohnung-mieten/zürich?housingType=1&locationIds%5Bcities%5D=15526"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "zürich___de",
    path: "/z%C3%BCrich",
    meta: {"cityPage":{"name":"zürich","countryName":"switzerland","locationIds":{"regions":[],"cities":[15526],"areas":[]},"searchUrl":"/suche/wohnung-mieten/zürich?housingType=1&locationIds%5Bcities%5D=15526"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "genf___de",
    path: "/genf",
    meta: {"cityPage":{"name":"genf","countryName":"switzerland","locationIds":{"regions":[],"cities":[17326],"areas":[]},"searchUrl":"/suche/wohnung-mieten/genf?housingType=1&locationIds%5Bcities%5D=17326"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "basel___de",
    path: "/basel",
    meta: {"cityPage":{"name":"basel","countryName":"switzerland","locationIds":{"regions":[],"cities":[16143],"areas":[]},"searchUrl":"/suche/wohnung-mieten/basel?housingType=1&locationIds%5Bcities%5D=16143"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "bern___de",
    path: "/bern",
    meta: {"cityPage":{"name":"bern","countryName":"switzerland","locationIds":{"regions":[],"cities":[16154],"areas":[]},"searchUrl":"/suche/wohnung-mieten/bern?housingType=1&locationIds%5Bcities%5D=16154"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "lausanne___de",
    path: "/lausanne",
    meta: {"cityPage":{"name":"lausanne","countryName":"switzerland","locationIds":{"regions":[],"cities":[19769],"areas":[]},"searchUrl":"/suche/wohnung-mieten/lausanne?housingType=1&locationIds%5Bcities%5D=19769"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "winterthur___de",
    path: "/winterthur",
    meta: {"cityPage":{"name":"winterthur","countryName":"switzerland","locationIds":{"regions":[],"cities":[20930],"areas":[]},"searchUrl":"/suche/wohnung-mieten/winterthur?housingType=1&locationIds%5Bcities%5D=20930"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "luzern___de",
    path: "/luzern",
    meta: {"cityPage":{"name":"luzern","countryName":"switzerland","locationIds":{"regions":[],"cities":[17928],"areas":[]},"searchUrl":"/suche/wohnung-mieten/luzern?housingType=1&locationIds%5Bcities%5D=17928"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "st.-gallen___de",
    path: "/st.-gallen",
    meta: {"cityPage":{"name":"st. gallen","countryName":"switzerland","locationIds":{"regions":[],"cities":[18236],"areas":[]},"searchUrl":"/suche/wohnung-mieten/st.-gallen?housingType=1&locationIds%5Bcities%5D=18236"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  },
  {
    name: "lugano___de",
    path: "/lugano",
    meta: {"cityPage":{"name":"lugano","countryName":"switzerland","locationIds":{"regions":[],"cities":[19284],"areas":[]},"searchUrl":"/suche/wohnung-mieten/lugano?housingType=1&locationIds%5Bcities%5D=19284"}},
    component: () => import("/app/browser/modules/city-pages/pages.vue")
  }
]