
// @ts-nocheck
import locale_de_46json_9e2c4717 from "#nuxt-i18n/9e2c4717";
import locale_de_46json_63d14031 from "#nuxt-i18n/63d14031";

export const localeCodes =  [
  "de"
]

export const localeLoaders = {
  de: [
    {
      key: "locale_de_46json_9e2c4717",
      load: () => Promise.resolve(locale_de_46json_9e2c4717),
      cache: true
    },
    {
      key: "locale_de_46json_63d14031",
      load: () => Promise.resolve(locale_de_46json_63d14031),
      cache: true
    }
  ]
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/da2de1aa" /* webpackChunkName: "config_i18n_46config_46ts_da2de1aa" */),
  () => import("#nuxt-i18n/d54f0d95" /* webpackChunkName: "config_i18n_46config_46ts_d54f0d95" */),
  () => import("#nuxt-i18n/d54f0d95" /* webpackChunkName: "config_i18n_46config_46ts_d54f0d95" */)
]

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    jit: true,
    strictMessage: false,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "de",
      language: "de",
      files: [
        "/app/layers/ui/i18n/locales/de.json",
        "/app/browser/i18n/locales/de.json"
      ]
    }
  ],
  defaultLocale: "de",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_except_default",
  lazy: false,
  langDir: "/app/browser/i18n/locales",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: false,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "i18n_redirected",
    cookieSecure: false,
    fallbackLocale: "",
    redirectOn: "root",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "de",
    language: "de",
    files: [
      {
        path: "/app/layers/ui/i18n/locales/de.json",
        cache: undefined
      },
      {
        path: "/app/browser/i18n/locales/de.json",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/