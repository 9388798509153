import { defineStore } from 'pinia';

/**
 * TODO: We might want to move toasts and other message types to this store.
 */
export type SystemMessageType = 'banner';

export interface SystemMessage {
  id: string;
  type: SystemMessageType;
  content: string | Component;
  props?: Record<string, unknown>;
  duration?: number;
}

export interface SystemState {
  messages: SystemMessage[];
}

export interface NewSystemMessage {
  type: SystemMessageType;
  content: string | Component;
  props?: Record<string, unknown>;
  duration?: number;
}

export const useSystemStore = defineStore('system', () => {
  const messages = ref<SystemMessage[]>([]);

  const banners = computed(() => messages.value.filter((msg) => msg.type === 'banner'));

  const addMessage = (newMessage: NewSystemMessage, customId?: string): string => {
    const id = customId || generateUniqueId();
    const message: SystemMessage = { id, ...newMessage };
    messages.value.push(message);

    if (newMessage.duration) {
      setTimeout(() => removeMessage(id), newMessage.duration);
    }

    return id;
  };

  const removeMessage = (id: string): void => {
    const index = messages.value.findIndex((msg) => msg.id === id);
    if (index !== -1) {
      messages.value.splice(index, 1);
    }
  };

  const clearAllMessages = (): void => {
    messages.value = [];
  };

  return { messages, banners, addMessage, removeMessage, clearAllMessages };
});

export default useSystemStore;
