import TwBannerWeakPassword from '~/components/banner/weak-password.vue';

export default defineNuxtRouteMiddleware(async () => {
  const isAuthenticated = useAuthState();
  const accountStore = useAccountStore();
  const systemStore = useSystemStore();
  /**
   * After initializing auth state, init user related states if applicable.
   */
  if (isAuthenticated.value && accountStore.initializedAt === undefined) {
    await accountStore.loadData();

    if (accountStore.user?.weakPasswordDetectedAt) {
      if (import.meta.client) {
        systemStore.addMessage({
          type: 'banner',
          content: markRaw(TwBannerWeakPassword),
        });
      }
    }
  }
});
