import { createGtm } from "@gtm-support/vue-gtm";

/**
 * Plugin for Google Tag Manager
 * @see https://github.com/gtm-support/vue-gtm
 *
 * Note that the integrated tracking of routing views is disabled, because we
 * have to trigger the page_view event manually due to the requirement of
 * adding the "traffic_type" property to the event object
 *
 * @see https://tauschwohnung.youtrack.cloud/issue/TRA-24/traffictype-immer-ins-dataLayer-schreiben
 */
export default defineNuxtPlugin((nuxtApp) => {
  const vueGtm = createGtm({
    id: useRuntimeConfig().public.gtmId,
    enabled: true,
    debug: process.env.NODE_ENV === "development",
    loadScript: true,
    // vueRouter: useRouter(),
  });

  nuxtApp.vueApp.use(vueGtm);
});
