import { useBaseStore } from '@/stores/base';
import { useBackendRequests } from '@/modules/backend/composables/use-backend-requests';

export function useUnseenConversations() {
  const baseStore = useBaseStore();
  const { chatAPI } = useBackendRequests();

  async function getUnseenConversations() {
    try {
      const response = await chatAPI.getUnseenCount();
      const { count } = response.data;
      baseStore.state.countOfUnseenConversations = Number(count) || 0;
    } catch (error) {
      console.error('Error in initUnseenConversations:', error);
    }
  }

  return {
    getUnseenConversations,
  };
}
