export const VERSION_STATE_KEY = 'localAppVersionTimestamp';
export const UPDATE_AVAILABLE_STATE_KEY = 'appUpdateAvailable';

export default function () {
    const config = useRuntimeConfig();

    const localAppVersion = useState<Date>(VERSION_STATE_KEY, undefined);
    const appUpdateAvailable = useState<boolean>(UPDATE_AVAILABLE_STATE_KEY, () => false);

    async function checkAppVersion() {

        try {
            const data = await $fetch<{ timestamp: string }>(`${config.public.apiUrl}/deployments/latest`);

            if (!data?.timestamp) {
                throw new Error('Could not check for app version. No timestamp found in api response.');
            }

            const timestamp = new Date(data.timestamp);

            if (!localAppVersion.value) {
                localAppVersion.value = timestamp;
            }

            appUpdateAvailable.value = localAppVersion.value < timestamp;

            return true
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    return {
        checkAppVersion,
        localAppVersion,
        appUpdateAvailable,
    }
}
