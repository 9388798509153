<script setup lang="ts">
const { locale } = useI18n();
useHead({
  htmlAttrs: {
    lang: locale,
  },
});
</script>

<template>
  <tw-ui-top-bar />
  <nuxt-layout>
    <nuxt-page />
  </nuxt-layout>
</template>
