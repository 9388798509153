import {
  type AccountActiveProductExecutionsResponse,
  type AccountActiveProductsResponse,
  type AccountCheckConfirmationCodeResponse,
  type AccountComplainResponse,
  type AccountConversationConfigResponse,
  type AccountCSRFTokenForPasswordResetResponse,
  type AccountDeleteResponse,
  type AccountHousingResponse,
  type AccountLoginResponse,
  type AccountLogoutResponse,
  type AccountPurchaseProductsResponse,
  type AccountResetPasswordResponse,
  type AccountSetNewPasswordResponse,
  type AccountStatisticsResponse,
  type AccountSubscriptionCancelResponse,
  type AccountSubscriptionResponse,
  type AccountWithdrawNoticeOfTerminationResponse,
  type AuthPreserveRegistrationFormDataResponse,
  type ChatArchiveRoomResponse,
  type ChatItemResponse,
  type ChatListResponse,
  type ChatMarkRoomAsUnreadResponse,
  type ChatNewerMessagesResponse,
  type ChatOlderMessagesResponse,
  type ChatResponseTimeAndRateResponse,
  type ChatRestoreRoomResponse,
  type ChatSendMessageResponse,
  type ChatStatusCountResponse,
  type ChatUnseenCountResponse,
  type CircularMatchChatListResponse,
  type CurrentWelcomePercentageResponse,
  type CurrentDiscountResponse,
  type DashboardMatchesDataResponse,
  type DashboardNumberOfTemporaryMatchesDataResponse,
  type DashboardTemporaryMatchesDataResponse,
  type FeatureListResponse,
  type HousingActivateResponse,
  type HousingActiveExportsResponse,
  type HousingDeactivateResponse,
  type HousingDeleteImageResponse,
  type HousingEditResponse,
  type HousingHideResponse,
  type HousingLikeResponse,
  type HousingResponse,
  type HousingSaveViewResponse,
  type HousingSimilarResponse,
  type HousingStaticFormDataResponse,
  type HousingUnhideResponse,
  type HousingUnlikeResponse,
  type HousingUpdateImageOrderResponse,
  type HousingUpdateResponse,
  type HousingUploadImageResponse,
  type MarketingTrackingResponse,
  type MatchIntermediatesResponse,
  type MatchPartyCountsResponse,
  type OfferListResponse,
  type OfferMismatchInfoResponse,
  type OfferResponse,
  type OfferSetInterestResponse,
  type OfferStartWatchingResponse,
  type OfferStatusCountResponse,
  type OfferStopWatchingResponse,
  type OfferSwapTypeCountResponse,
  type RandomTestimonialResponse,
  type RegistrationResponse,
  type SearchListResponse,
  type SearchLocationsResponse,
  type SSOProvidersResponse,
  type SystemActiveProductsByKeyResponse,
  type UserCurrentlyLoggedInResponse,
  type UserDeleteProfileImageResponse,
  type UserExportsUpdateResponse,
  type UserIsLoggedInResponse,
  type UserNotificationsUpdateResponse,
  type UserUpdateEmailResponse,
  type UserUpdatePasswordResponse,
  type UserUpdateProfileImageResponse,
  type CurrentActiveProductsByProductKeyCity,
} from '../types';

import type AccountSettingsNotifications from '~/models/AccountSettingsNotifications';
import type AccountSettingsExports from '~/models/AccountSettingsExports';
import type { ActiveProductsKeys } from '~/models/App';
import type { HousingImage } from '~/models/Housing';
import type { AxiosRequestConfig } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BackendFetchBody = BodyInit | Record<string, any> | null | undefined;

export type BackendFetchOptions = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS' | 'HEAD';
  headers?: Record<string, string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query?: Record<string, any>;
  body?: BackendFetchBody;
  signal?: AbortSignal | null | undefined;
};

export function useBackendRequests() {
  const { $request, $axiosRequest } = useNuxtApp();
  const { getBackendURL } = useBackendURLs();

  const accountAPI = {
    checkConfirmationCode: function (userId: string | number, code: string, options?: BackendFetchOptions) {
      return $request<AccountCheckConfirmationCodeResponse>(
        getBackendURL('account.checkConfirmationCode', {
          id: userId,
          code: code,
        }),
        options
      );
    },

    withdrawNoticeOfTermination: function (options?: BackendFetchOptions) {
      return $request<AccountWithdrawNoticeOfTerminationResponse>(getBackendURL('account.withdrawNoticeOfTermination'), options);
    },

    getActiveProductExecutions: function (options?: BackendFetchOptions) {
      return $request<AccountActiveProductExecutionsResponse>(getBackendURL('account.getActiveProductExecutions'), options);
    },

    getCSRFTokenForPasswordReset: function (options?: BackendFetchOptions) {
      return $request<AccountCSRFTokenForPasswordResetResponse>(getBackendURL('account.getCSRFTokenForPasswordReset'), options);
    },

    getCurrentSubscription: function (options?: BackendFetchOptions) {
      return $request<AccountSubscriptionResponse>(getBackendURL('account.getSubscription'), options);
    },

    cancelCurrentSubscription: function (options?: BackendFetchOptions) {
      return $request<AccountSubscriptionCancelResponse>(getBackendURL('account.cancelSubscription'), options);
    },

    login: function (email: string, password: string, stayLoggedIn: boolean, options?: BackendFetchOptions) {
      return $request<AccountLoginResponse>(getBackendURL('account.login'), {
        method: 'POST',
        body: {
          email,
          password,
          stay_logged_in: stayLoggedIn ? '1' : '0',
        },
        ...options,
      });
    },

    logout: function (options?: BackendFetchOptions) {
      return $request<AccountLogoutResponse>(getBackendURL('account.logout'), {
        method: 'POST',
        ...options,
      });
    },

    setNewPassword: function (userId: string, password: string, confirmationCode: string, options?: BackendFetchOptions) {
      return $request<AccountSetNewPasswordResponse>(getBackendURL('account.setNewPassword'), {
        method: 'POST',
        body: {
          userId,
          password,
          confirmationCode,
        },
        ...options,
      });
    },

    resetPassword: function (email: string, csrf: string, options?: BackendFetchOptions) {
      return $request<AccountResetPasswordResponse>(getBackendURL('account.resetPassword'), {
        method: 'POST',
        body: {
          email,
          csrf,
        },
        ...options,
      });
    },

    purchase: function (productIds: number[], options?: BackendFetchOptions) {
      return $request<AccountPurchaseProductsResponse>(getBackendURL('account.purchase'), {
        method: 'POST',
        body: {
          productIds: productIds.join(','),
        },
        ...options,
      });
    },

    getActiveProducts: function (options?: BackendFetchOptions) {
      return $request<AccountActiveProductsResponse>(getBackendURL('account.getActiveProducts'), options);
    },

    complain: function (reason: string, explanation: string, offerId: string | number, offerType = 'housing', options?: BackendFetchOptions) {
      return $request<AccountComplainResponse>(getBackendURL('account.complain'), {
        method: 'POST',
        body: {
          reason,
          explanation,
          offerId,
          offerType,
        },
        ...options,
      });
    },

    delete: function (reason: string, feedback: string, options?: BackendFetchOptions) {
      return $request<AccountDeleteResponse>(getBackendURL('account.delete'), {
        method: 'POST',
        body: {
          reason_radio: reason,
          reason_text: feedback,
        },
        ...options,
      });
    },
  };

  const authAPI = {
    getSSOProviders: function (type: string, redirect?: string, options?: BackendFetchOptions) {
      const route = redirect ? 'auth.sso.redirect' : 'auth.sso';
      return $request<SSOProvidersResponse>(getBackendURL(route, redirect ? { type, redirect } : { type }), options);
    },

    preserveRegistrationFormData: function (body: BackendFetchBody, options?: BackendFetchOptions) {
      return $request<AuthPreserveRegistrationFormDataResponse>(getBackendURL('auth.preserve'), {
        method: 'POST',
        body,
        ...options,
      });
    },
  };

  const chatAPI = {
    getList: function (status: string, query: string, page: number, perPage: number, options?: BackendFetchOptions) {
      return $request<ChatListResponse>(
        getBackendURL('chat.getList', {
          status,
          query,
          page,
          perPage,
        }),
        options
      );
    },

    getNewerMessages: function (roomId: number, messageId: number, options?: BackendFetchOptions) {
      return $request<ChatNewerMessagesResponse>(
        getBackendURL('chat.getNewerMessages', {
          roomId,
          messageId,
        }),
        options
      );
    },

    getConversationUsage: function (options?: BackendFetchOptions) {
      return $request<AccountConversationConfigResponse>(getBackendURL('chat.getConversationUsage'), options);
    },

    getResponseTimeAndRate: function (guid: string, options?: BackendFetchOptions) {
      return $request<ChatResponseTimeAndRateResponse>(
        getBackendURL('chat.getResponseTimeAndRate', {
          guid,
        }),
        options
      );
    },

    markRoomAsUnreadByRoomId: function (roomId: number, options?: BackendFetchOptions) {
      return $request<ChatMarkRoomAsUnreadResponse>(
        getBackendURL('chat.markRoomAsUnreadByRoomId', {
          roomId,
        }),
        options
      );
    },

    getOlderMessages: function (roomId: number, messageId?: number, options?: BackendFetchOptions) {
      let url = getBackendURL('chat.getOlderMessages', { roomId });

      if (messageId) {
        url = `${url}&oldestMessageId=${messageId}`;
      }

      return $request<ChatOlderMessagesResponse>(url, options);
    },

    getItem: function (targetUserGuid: string, intermediateUserGuid?: string, options?: BackendFetchOptions) {
      let url = getBackendURL('chat.getItem', {
        targetUserGuid,
      });

      if (intermediateUserGuid) {
        url = `${url}&guid2=${intermediateUserGuid}`;
      }
      return $request<ChatItemResponse>(url, options);
    },

    getUnseenCount: function (options?: BackendFetchOptions) {
      return $request<ChatUnseenCountResponse>(getBackendURL('chat.getUnseenCount'), options);
    },

    getStatusCounts: function (options?: BackendFetchOptions) {
      return $request<ChatStatusCountResponse>(getBackendURL('chat.getStatusCounts'), options);
    },

    archiveByRoomId: function (roomId: number, options?: BackendFetchOptions) {
      return $request<ChatArchiveRoomResponse>(
        getBackendURL('chat.doArchive', {
          roomId,
        }),
        options
      );
    },

    restoreByRoomId: function (roomId: number, options?: BackendFetchOptions) {
      return $request<ChatRestoreRoomResponse>(
        getBackendURL('chat.doRestore', {
          roomId,
        }),
        options
      );
    },

    sendMessage: function (body: BackendFetchBody, options?: BackendFetchOptions) {
      return $request<ChatSendMessageResponse>(getBackendURL('chat.send'), {
        method: 'POST',
        body,
        ...options,
      });
    },

    getCircularMatchChatListItems: function (targetUserId: string, circularMatchChatStatus: string, page: number, perPage: number, options?: BackendFetchOptions) {
      return $request<CircularMatchChatListResponse>(
        getBackendURL('chat.getCircularMatchChatListItems', {
          targetUserId,
          circularMatchChatStatus,
          page,
          perPage,
        }),
        options
      );
    },
  };

  const dashboardAPI = {
    getStatistics: function (options?: BackendFetchOptions) {
      return $request<AccountStatisticsResponse>(getBackendURL('dashboard.getStatistics'), options);
    },

    getMatches: function (options?: BackendFetchOptions) {
      return $request<DashboardMatchesDataResponse>(getBackendURL('dashboard.getMatchesData'), options);
    },

    getTemporaryMatches: function (body: object, options?: BackendFetchOptions) {
      return $request<DashboardTemporaryMatchesDataResponse>(getBackendURL('dashboard.getTemporaryMatchesData'), {
        method: 'POST',
        body,
        ...options,
      });
    },

    getNumberOfTemporaryMatches: function (body: object, options?: BackendFetchOptions) {
      return $request<DashboardNumberOfTemporaryMatchesDataResponse>(getBackendURL('dashboard.getNumberOfTemporaryMatches'), {
        method: 'POST',
        body,
        ...options,
      });
    },
  };

  const housingAPI = {
    getActiveExports: function (housingId: number | string, options?: BackendFetchOptions) {
      return $request<HousingActiveExportsResponse>(getBackendURL('housing.getActiveExports', { housingId }), options);
    },

    getSimilarHousingsById: function (housingId: number | string, options?: BackendFetchOptions) {
      return $request<HousingSimilarResponse>(getBackendURL('housing.getSimilar', { housingId }), options);
    },

    deleteImage: function (imageId: number, options?: BackendFetchOptions) {
      return $request<HousingDeleteImageResponse>(
        getBackendURL('housing.deleteImage', {
          imageId,
        }),
        options
      );
    },

    getForCurrentUser: function (options?: BackendFetchOptions) {
      return $request<AccountHousingResponse>(getBackendURL('housing.getForCurrentUser'), options);
    },

    getStaticFormData: function (options?: BackendFetchOptions) {
      return $request<HousingStaticFormDataResponse>(getBackendURL('housing.getStaticFormData'), options);
    },

    getById: function (housingId: number | string, options?: BackendFetchOptions) {
      return $request<HousingResponse>(getBackendURL('housing.getById', { housingId }), options);
    },

    getForUserId: function (userId: number | string, options?: BackendFetchOptions) {
      return $request<HousingResponse>(getBackendURL('housing.getForUserId', { userId }), options);
    },

    edit: function (housingId: number | string, body: BackendFetchBody, options?: BackendFetchOptions) {
      return $request<HousingEditResponse>(getBackendURL('housing.edit', { housingId }), {
        method: 'POST',
        body,
        ...options,
      });
    },

    saveView: function (housingId: number | string, options?: BackendFetchOptions) {
      return $request<HousingSaveViewResponse>(
        getBackendURL('housing.saveView', {
          housingId,
        }),
        options
      );
    },

    update: function (body: BackendFetchBody, options?: BackendFetchOptions) {
      return $request<HousingUpdateResponse>(getBackendURL('housing.update'), {
        method: 'POST',
        body,
        ...options,
      });
    },

    /**
     * TODO: Use fetch here, if possible
     */
    uploadImage: function (img: File, options?: AxiosRequestConfig) {
      const bodyFormData = new FormData();
      bodyFormData.append('img', img);
      return $axiosRequest.post<HousingUploadImageResponse>(getBackendURL('housing.uploadImage'), bodyFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        ...options,
      });
    },

    doLike: function (housingId: number | string, options?: BackendFetchOptions) {
      return $request<HousingLikeResponse>(getBackendURL('housing.like', { housingId }), options);
    },

    doUnlike: function (housingId: number | string, options?: BackendFetchOptions) {
      return $request<HousingUnlikeResponse>(getBackendURL('housing.unlike', { housingId }), options);
    },

    doHide: function (housingId: number | string, options?: BackendFetchOptions) {
      return $request<HousingHideResponse>(getBackendURL('housing.hide', { housingId }), options);
    },

    doUnhide: function (housingId: number | string, options?: BackendFetchOptions) {
      return $request<HousingUnhideResponse>(getBackendURL('housing.unhide', { housingId }), options);
    },

    activate: function (options?: BackendFetchOptions) {
      return $request<HousingActivateResponse>(getBackendURL('housing.activate'), {
        method: 'POST',
        ...options,
      });
    },

    deactivate: function (reason: string, additionalInfo: string, options?: BackendFetchOptions) {
      return $request<HousingDeactivateResponse>(getBackendURL('housing.deactivate'), {
        method: 'POST',
        body: {
          reasonText: '',
          reasonRadio: reason,
          reasonAdditionalInfo: additionalInfo,
        },
        ...options,
      });
    },

    orderImages: function (images: HousingImage[], options?: BackendFetchOptions) {
      const body: { [key: string]: string } = {};

      images.forEach((image, index: number) => {
        body[`newOrder[${image.id}]`] = index.toString();
      });

      return $request<HousingUpdateImageOrderResponse>(getBackendURL('housing.orderImages'), {
        method: 'POST',
        body,
        ...options,
      });
    },
  };

  const matchAPI = {
    getIntermediatesByTargetUserId: function (targetUserId: number | string, options?: BackendFetchOptions) {
      return $request<MatchIntermediatesResponse>(getBackendURL('match.getIntermediatesByTargetUserId', { targetUserId }), options);
    },

    getPartyCountsByTargetUserId: function (targetUserId: number | string, options?: BackendFetchOptions) {
      return $request<MatchPartyCountsResponse>(getBackendURL('match.getPartyCountsByTargetUserId', { targetUserId }), options);
    },
  };

  const offerAPI = {
    getSwapTypeCountByStatus: function (status: string, options?: BackendFetchOptions) {
      return $request<OfferSwapTypeCountResponse>(getBackendURL('offer.getSwapTypeCountByStatus', { status }), options);
    },

    getMismatchInfoByTargetUserId: function (targetUserId: number | string, options?: BackendFetchOptions) {
      return $request<OfferMismatchInfoResponse>(getBackendURL('offer.getMismatchInfoByTargetUserId', { targetUserId }), options);
    },

    getByHousingId: function (housingId: number | string, options?: BackendFetchOptions) {
      return $request<OfferResponse>(getBackendURL('offer.getByHousingId', { housingId }), options);
    },

    startWatching: function (targetUserId: number | string, options?: BackendFetchOptions) {
      return $request<OfferStartWatchingResponse>(getBackendURL('offer.startWatching'), {
        method: 'POST',
        body: { targetUserId },
        ...options,
      });
    },

    getStatusCount: function (options?: BackendFetchOptions) {
      return $request<OfferStatusCountResponse>(getBackendURL('offer.getStatusCount'), options);
    },

    stopWatching: function (targetUserId: number | string, options?: BackendFetchOptions) {
      return $request<OfferStopWatchingResponse>(getBackendURL('offer.stopWatching'), {
        method: 'POST',
        body: { targetUserId },
        ...options,
      });
    },

    setInterest: function (targetUserId: number | string, interest: 'yes' | 'no' | '', options?: BackendFetchOptions) {
      return $request<OfferSetInterestResponse>(getBackendURL('offer.setInterest'), {
        method: 'POST',
        body: {
          targetUserId: targetUserId,
          interest: interest,
        },
        ...options,
      });
    },

    getByFilters: function (body: BackendFetchBody, options?: BackendFetchOptions) {
      return $request<OfferListResponse>(getBackendURL('offer.getByFilters'), {
        method: 'POST',
        body,
        ...options,
      });
    },

    getBySearch: function (body: BackendFetchBody, options?: BackendFetchOptions) {
      return $request<OfferListResponse>(getBackendURL('offer.getBySearch'), {
        method: 'POST',
        body,
        ...options,
      });
    },
  };

  const searchAPI = {
    getSearchResult: function (options?: BackendFetchOptions) {
      return $request<SearchListResponse>(getBackendURL('search.getSearchResult'), options);
    },

    getLocations: function (options?: BackendFetchOptions) {
      return $request<SearchLocationsResponse>(getBackendURL('search.getLocations'), options);
    },
  };

  const systemAPI = {
    getActiveProductsByKey: function (productKey: ActiveProductsKeys, options?: BackendFetchOptions) {
      return $request<SystemActiveProductsByKeyResponse>(
        getBackendURL('system.activeProductsByKey', {
          productKey: productKey,
        }),
        options
      );
    },

    getRandomTestimonial: function (options?: BackendFetchOptions) {
      return $request<RandomTestimonialResponse>(getBackendURL('system.getRandomTestimonial'), options);
    },

    getCurrentDiscount: function (options?: BackendFetchOptions) {
      return $request<CurrentDiscountResponse>(getBackendURL('system.getCurrentDiscount'), options);
    },

    sendMarketingTracking: function (query: string, body: Record<string, unknown>, options?: BackendFetchOptions) {
      return $request<MarketingTrackingResponse>(getBackendURL('system.sendTracking', { query }), {
        method: 'POST',
        body,
        ...options,
      });
    },

    getFeatureList: function (options?: BackendFetchOptions) {
      return $request<FeatureListResponse>(getBackendURL('system.featureList'), options);
    },

    register: function (body: BackendFetchBody, options?: BackendFetchOptions) {
      return $request<RegistrationResponse>(getBackendURL('system.register'), {
        method: 'POST',
        body,
        ...options,
      });
    },

    getLocationsForGeoIds: function (geoIds: string, options?: BackendFetchOptions) {
      return $request<SearchLocationsResponse>(getBackendURL('locations.getLocationsForGeoIds', { geoIds }), options);
    },

    getCurrentWelcomePercentage: function (options?: BackendFetchOptions) {
      return $request<CurrentWelcomePercentageResponse>(getBackendURL('system.currentWelcomePercentage'), options);
    },

    getActiveProductsByProductKeyCity: function (productKey: string, city: string, country: string, options?: BackendFetchOptions) {
      return $request<CurrentActiveProductsByProductKeyCity>(getBackendURL('system.getActiveProductsByProductKeyCity', { productKey, city, country }), options);
    },
  };

  const userAPI = {
    getLoggedInUser: function (options?: BackendFetchOptions) {
      return $request<UserCurrentlyLoggedInResponse>(getBackendURL('user.loggedIn'), options);
    },

    updateNotifications: function (body: AccountSettingsNotifications, options?: BackendFetchOptions) {
      return $request<UserNotificationsUpdateResponse>(getBackendURL('user.updateNotifications'), {
        method: 'POST',
        body,
        ...options,
      });
    },

    /**
     * TODO: Use fetch here, if possible
     */
    uploadProfileImage: function (img: File, options?: AxiosRequestConfig) {
      const bodyFormData = new FormData();
      bodyFormData.append('img', img);
      bodyFormData.append('tmp_name', 'profile_image');

      return $axiosRequest.post<{ data: UserUpdateProfileImageResponse }>(getBackendURL('user.uploadProfileImage'), bodyFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        ...options,
      });
    },

    deleteProfileImage: function (options?: BackendFetchOptions) {
      return $request<UserDeleteProfileImageResponse>(getBackendURL('user.deleteProfileImage'), options);
    },

    updateExports: function (body: AccountSettingsExports, options?: BackendFetchOptions) {
      return $request<UserExportsUpdateResponse>(getBackendURL('user.updateExports'), {
        method: 'POST',
        body,
        ...options,
      });
    },

    updatePassword: function (currentPassword: string, newPassword: string, newPasswordConfirm: string, options?: BackendFetchOptions) {
      return $request<UserUpdatePasswordResponse>(getBackendURL('user.updatePassword'), {
        method: 'POST',
        body: {
          password: currentPassword,
          passwordNew: newPassword,
          passwordConfirm: newPasswordConfirm,
        },
        ...options,
      });
    },

    updateEmail: function (email: string, options?: BackendFetchOptions) {
      return $request<UserUpdateEmailResponse>(getBackendURL('user.updateEmail'), {
        method: 'POST',
        body: {
          email,
        },
        ...options,
      });
    },

    isLoggedIn: function (options?: BackendFetchOptions) {
      return $request<UserIsLoggedInResponse>(getBackendURL('user.isLoggedIn'), options);
    },
  };
  return {
    accountAPI,
    authAPI,
    chatAPI,
    dashboardAPI,
    housingAPI,
    matchAPI,
    offerAPI,
    searchAPI,
    systemAPI,
    userAPI,
  };
}

export default useBackendRequests;
