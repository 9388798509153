import type { ShoutOuts } from '~/models/ShoutOuts';

export const useShoutOutsStore = defineStore('shoutouts', () => {
  const { systemAPI } = useBackendRequests();

  const state = ref<ShoutOuts>({
    discount: {
      percentageDiscount: null,
      lowestPricePerMonth: null,
      originalLowestPricePerMonth: null,
      maxSavings: null,
      fetchingData: false,
    },
    multiPortal: {
      isPurchased: false,
    },
    successStory: {
      name: undefined as string | undefined,
      quoteVideo: null,
      imageUrl: undefined as string | undefined,
      totalSubscriptionDuration: undefined,
      totalSubscriptionDurationInMonths: undefined,
      fetchingData: false,
    },
    initializedAt: undefined,
  });

  async function fetchDiscount() {
    try {
      state.value.discount.fetchingData = true;
      const response = await systemAPI.getCurrentDiscount();
      if (response.data && response.data.discount?.percentageDiscount && response.data?.maxSavings) {
        state.value.discount.percentageDiscount = response.data.discount.percentageDiscount;
        state.value.discount.maxSavings = response.data.maxSavings;
        state.value.discount.lowestPricePerMonth = response.data.lowestPricePerMonth;
        state.value.discount.originalLowestPricePerMonth = response.data.originalLowestPricePerMonth;
      }
    } catch (error) {
      console.error('Error fetching discount', error);
    } finally {
      state.value.discount.fetchingData = false;
    }
  }

  async function fetchSuccessStory() {
    try {
      state.value.successStory.fetchingData = true;
      const response = await systemAPI.getRandomTestimonial();
      if (response.data.testimonial) {
        const { quote, imageUrl, name, totalSubscriptionDuration, totalSubscriptionDurationInMonths } = response.data.testimonial;
      state.value.successStory.name = name
      state.value.successStory.quoteVideo = quote
      state.value.successStory.imageUrl = imageUrl
      state.value.successStory.totalSubscriptionDuration = totalSubscriptionDuration
      state.value.successStory.totalSubscriptionDurationInMonths = totalSubscriptionDurationInMonths
      }
    } catch (error) {
      console.error('Error fetching success story', error);
    } finally {
      state.value.successStory.fetchingData = false;
    }
  }

  return {
    state,
    fetchDiscount,
    fetchSuccessStory,
  };
});

export default useShoutOutsStore;
