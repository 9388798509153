/**
 * Central repository of known api urls. Retrievable by key in dot-notation.
 *
 * Usage Examples:
 * ```ts
 * const { getBackendURL } = useBackendURLs();
 * const url = getBackendURL('account.uploadProfileImage');
 * ```
 *
 * ```ts
 * const { getBackendURL } = useBackendURLs();
 * const url = getBackendURL('chat.doArchive', { userId: 1 });
 * ```
 */
export const backendURLs = {
  // ACCOUNT
  'account.checkConfirmationCode': '/account/ajaxValidateConfirmationCode/:id/:code',
  'account.withdrawNoticeOfTermination': '/account/ajaxWithdrawNoticeOfTermination',
  'account.getActiveProductExecutions': '/account/ajaxGetActiveProductExecutions',
  'account.getCSRFTokenForPasswordReset': '/account/ajaxGetCsrfForPasswordReset',
  'account.getSubscription': '/account/ajaxGetCurrentSubscription',
  'account.cancelSubscription': '/account/ajaxCancelSubscription',
  'account.getActiveProducts': '/account/ajaxGetActiveProducts',
  'account.setNewPassword': '/account/ajaxResetPassword',
  'account.resetPassword': '/account/ajaxPasswordReset',
  'account.complain': '/complaint/complain',
  'account.delete': '/account/ajaxDelete',
  'account.logout': '/account/ajaxLogout',
  'account.purchase': '/payment/ajaxBuy',
  'account.login': '/account/ajaxLogin',

  // AUTH
  'auth.preserve': '/auth/preserve-registration-form-data',
  'auth.sso': '/auth/backward-url/:type',
  'auth.sso.redirect': '/auth/backward-url/:type?redirect=:redirect',

  // CHAT
  'chat.getList': '/messaging/ajaxGetChatListItems?status=:status&query=:query&page=:page&perPage=:perPage',
  'chat.getNewerMessages': '/messaging/ajaxGetNewerMessages?roomId=:roomId&newestMessageId=:messageId',
  'chat.getConversationUsage': '/messaging/ajaxGetConversationUsage',
  'chat.getResponseTimeAndRate': '/messaging/ajaxGetResponseTimeAndRate?guid=:guid',
  'chat.markRoomAsUnreadByRoomId': '/messaging/ajaxMarkRoomAsUnread/:roomId',
  'chat.getOlderMessages': '/messaging/ajaxGetOlderMessages?roomId=:roomId',
  'chat.getItem': '/messaging/ajaxGetChatListItem?guid1=:targetUserGuid',
  'chat.getUnseenCount': '/messaging/ajaxGetCountOfUnreadConversations',
  'chat.getStatusCounts': '/messaging/ajaxGetStatusCounts',
  'chat.dashboardData': '/messaging/ajaxGetDashboardData',
  'chat.doArchive': '/messaging/ajaxDoArchive/:roomId',
  'chat.doRestore': '/messaging/ajaxDoRestore/:roomId',
  'chat.getChatData': '/messaging/ajaxGetChatData/:id',
  'chat.archiveData': '/messaging/ajaxGetArchiveData',
  'chat.update': '/messaging/ajaxUpdate',
  'chat.send': '/messaging/ajaxSend',
  'chat.getCircularMatchChatListItems': '/messaging/ajaxGetCircularMatchChatListItems/:targetUserId?circularMatchChatStatus=:circularMatchChatStatus&page=:page&perPage=:perPage',

  // DASHBOARD
  'dashboard.getNumberOfTemporaryMatches': '/dashboard/getNumberOfTemporaryMatches',
  'dashboard.getTemporaryMatchesData': '/dashboard/getTemporaryMatchesData',
  'dashboard.getNotContactedData': '/dashboard/getNotContactedData',
  'dashboard.getNumberOfHousings': '/dashboard/getNumberOfHousings',
  'dashboard.getInterestedData': '/dashboard/getInterestedData',
  'dashboard.getStatistics': '/dashboard/ajaxGetStatisticsData',
  'dashboard.getMatchesData': '/dashboard/getMatchesData',
  'dashboard.getData': '/dashboard/ajaxGetDashboardData',
  'dashboard.getHiddenData': '/dashboard/getHiddenData',
  'dashboard.getLikedData': '/dashboard/getLikedData',

  // HOUSING
  'housing.getActiveExports': '/housingExport/ajaxGetActive?housingId=:housingId',
  'housing.getSimilar': '/housing/ajaxGetSimilarHousings/:housingId',
  'housing.getStaticFormData': '/housing/ajaxGetStaticFormData',
  'housing.deleteImage': '/image/deleteHousingImage/:imageId',
  'housing.getForCurrentUser': '/housing/ajaxGetOwnHousing',
  'housing.edit': '/ideal_housing/edit/:housingId?ajax=1',
  'housing.saveView': '/housing/ajaxSaveView/:housingId',
  'housing.getLocations': '/housing/ajaxGetLocations',
  'housing.uploadImage': '/image/uploadHousingImage',
  'housing.unlike': '/housing/ajaxUnlike/:housingId',
  'housing.unhide': '/housing/ajaxUnhide/:housingId',
  'housing.getById': '/housing/ajaxGet/:housingId',
  'housing.getForUserId': '/housing/ajaxGetForUserId?userId=:userId',
  'housing.deactivate': '/housing/ajaxDeactivate',
  'housing.like': '/housing/ajaxLike/:housingId',
  'housing.hide': '/housing/ajaxHide/:housingId',
  'housing.activate': '/housing/ajaxActivate',
  'housing.orderImages': '/image/newOrder',
  'housing.update': '/housing/ajaxUpdate',

  // Init Base Store
  'initBaseStore.getStaticFormData': '/initBaseStore/ajaxGetStaticFormData',
  'initBaseStore.getOwnHousing': '/initBaseStore/ajaxGetOwnHousing',


  // Locations
  'locations.getLocationsForGeoIds': '/search/getLocationsForGeoIds?geoIds=:geoIds',

  // MATCH
  'match.getIntermediatesByTargetUserId': '/match/get-intermediates/:targetUserId',
  'match.getPartyCountsByTargetUserId': '/match/get-party-counts/:targetUserId',

  // OFFER
  'offer.getSwapTypeCountByStatus': '/offer/get-swap-type-counts-for-status/:status',
  'offer.getMismatchInfoByTargetUserId': '/offer/why-no-match/:targetUserId',
  'offer.getByHousingId': '/offer/get-for-housing-id/:housingId',
  'offer.startWatching': '/link-actions/start-watching',
  'offer.getStatusCount': '/offer/get-statuses-count',
  'offer.stopWatching': '/link-actions/stop-watching',
  'offer.setInterest': '/link-actions/set-interest',
  'offer.getByFilters': '/offer/get-for-filter',
  'offer.getBySearch': '/offer/get-for-search',

  // SEARCH
  'search.getSearchResult': '/search/ajaxResult',
  'search.getLocations': '/search/getLocations',
  'search.getOwn': '/search/ajaxGetOwnSearch',

  // SYSTEM
  'system.activeProductsByKey': '/active-products?productKey=:productKey',
  'system.getRandomTestimonial': '/testimonial/ajaxGetRandom',
  'system.getCurrentDiscount': '/discount/ajaxGetCurrent',
  'system.sendTracking': '/ajaxMarketingTracking?:query',
  'system.featureList': '/feature-list',
  'system.register': '/ajaxRegistration',
  'system.currentWelcomePercentage': '/voucher/ajaxGetCurrentWelcomePercentage',
  'system.getActiveProductsByProductKeyCity': '/product/ajaxGetActiveProducts?productKey=:productKey&city=:city&country=:country',

  // USER
  'user.updateNotifications': '/user/ajaxUpdateNotifications',
  'user.deleteProfileImage': '/image/ajaxDeleteUserImage',
  'user.uploadProfileImage': '/image/uploadUserImage',
  'user.updateExports': '/user/ajaxUpdateAllowExport',
  'user.updatePassword': '/user/ajaxUpdatePassword',
  'user.loggedIn': '/user/ajaxGetLoggedInUser',
  'user.updateEmail': '/user/ajaxUpdateEmail',
  'user.isLoggedIn': '/user/ajaxIsLoggedIn',
} as const;

type BackendURLParams = Record<string, string | number>;

type BackendURLKey = keyof typeof backendURLs;

export const useBackendURLs = (baseUrl = '') => {
  const getBackendURL = (key: BackendURLKey, params?: BackendURLParams): string => {
    const url = backendURLs[key];
    if (!url) {
      throw new Error(`URL für Key '${key}' nicht gefunden.`);
    }

    if (!params) return baseUrl + url;

    const missingParams: string[] = [];
    const generatedUrl = url.replace(/:([a-zA-Z]+)/g, (_, paramKey) => {
      if (params[paramKey] === undefined) {
        missingParams.push(paramKey);
        return `:${paramKey}`;
      }
      return String(params[paramKey]);
    });

    if (missingParams.length > 0) {
      console.warn(`Fehlende Parameter: ${missingParams.join(', ')} für URL: ${url}`);
    }

    return baseUrl + generatedUrl;
  };

  return {
    getBackendURL,
  };
};

export default useBackendURLs;
