<script lang="ts" setup></script>

<template>
    <div>
        <img src="/images/loading.gif" />
    </div>
</template>

<style lang="scss" scoped>
div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        height: 100%;
    }
}
</style>
