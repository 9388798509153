export type Room = number;
export type TransferRoom = number;
export function normalizeTransferRoom(data: TransferRoom): Room {
  return +data.toFixed(1);
}

export interface HousingType {
  readonly id: number;
}

export type TransferHousingType = number;
export function normalizeTransferHousingType(data: TransferHousingType): HousingType {
  return {
    id: data,
  };
}

export interface HousingProperty {
  readonly id: number;
  readonly group: number;
}
export type TransferHousingProperty = [/*id*/ number, /*group*/ number];
export function normalizeTransferHousingProperty(data: TransferHousingProperty): HousingProperty {
  return {
    id: data[0],
    group: data[1],
  };
}

export interface HousingPropertyGroup {
  readonly id: number;
  readonly properties: number[];
}
export type TransferHousingPropertyGroup = [/*id*/ number, /*properties*/ number[]];
export function normalizeTransferHousingPropertyGroup(data: TransferHousingPropertyGroup): HousingPropertyGroup {
  return {
    id: data[0],
    properties: data[1],
  };
}

export interface PropertyType {
  id: number;
  key: string;
  inSearch: boolean;
}

export interface PropertyCategory {
  id: number;
  key: string;
  propertyTypes: PropertyType[];
}

export interface FeatureList {
  circularMatches: {
    enable: boolean;
  };
}
