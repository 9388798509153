export const useAuth = () => {
  const { accountAPI, userAPI } = useBackendRequests();

  const isAuthenticated = useAuthState();

  const requestStatus = ref<'idle' | 'pending' | 'success' | 'error'>('idle');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const requestError = ref<any>();
  const requestLastExecutedAt = ref<Date>();

  async function checkStatus() {
    requestStatus.value = 'pending';

    try {
      const response = await userAPI.isLoggedIn();
      requestStatus.value = 'success';
      isAuthenticated.value = response.isLoggedIn;
      requestLastExecutedAt.value = new Date();
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      requestError.value = error;
      requestStatus.value = 'error';
      throw error;
    }
  }

  function reset() {
    isAuthenticated.value = false;
    requestError.value = '';
    requestStatus.value = 'idle';
    requestLastExecutedAt.value = undefined;
  }

  async function authenticate(email: string, password: string, stayLoggedIn: boolean) {
    try {
      await accountAPI.login(email, password, stayLoggedIn);
      isAuthenticated.value = true;
      requestLastExecutedAt.value = new Date();
    } catch (error) {
      isAuthenticated.value = false;
      throw error;
    }
  }

  return {
    requestStatus,
    requestError,
    requestLastExecutedAt,
    reset,
    authenticate,
    checkStatus,
  };
};
