import type { LogData, LogLevel } from '~/models/Log';

/**
 * @see https://nuxt.com/docs/getting-started/error-handling
 */
export default defineNuxtPlugin((nuxtApp: any) => {
  const isDev = nuxtApp.$config.public.dev;
  const isClient = import.meta.client;
  const config = useRuntimeConfig();

  const logMessage = (level: 'error' | 'warning', type: string, message: any, context: Record<string, any> = {}) => {
    if (isDev) {
      const logFn = level === 'error' ? console.error : console.warn;
      logFn(`[${type} ${level}]:`, {
        message: message.message || message,
        ...context,
        stack: message.stack,
      });
    }
  };

  const reportMessage = async (message: any, additionalBodyInfo: Record<string, any> = {}, level: LogLevel = 'error') => {
    if (!config.public.errorUrl) {
      return;
    }

    try {
      const stackLine = message.stack?.split('\n')[1] || '';
      const match = stackLine.match(/:(\d+):(\d+)/);
      const line = match?.[1] ? parseInt(match[1]) : 0;
      const column = match?.[2] ? parseInt(match[2]) : 0;

      const messageBody: LogData = {
        timestamp: new Date().toISOString(),
        message: message.message || message,
        source: isClient ? 'client' : 'server',
        line,
        column,
        stack: message.stack || '',
        url: isClient ? window.location.href : '',
        userAgent: isClient ? navigator.userAgent : '',
        level,
      };

      await fetch(config.public.errorUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...messageBody, ...additionalBodyInfo }),
      });
    } catch (e) {
      if (isDev) {
        console.error('Failed to report message:', e);
      }
    }
  };

  /**
   * @see https://nuxt.com/docs/getting-started/error-handling#vue-errors
   */
  nuxtApp.hook('vue:error', (error: Error, instance: any, info: string) => {
    const context = {
      component: instance?.$options?.name || 'Unknown Component',
      info,
    };
    logMessage('error', 'Vue', error, context);
    reportMessage(
      error,
      {
        errorType: 'vue',
        ...context,
      },
      'error'
    );
  });

  nuxtApp.hook('vue:warn', (message: string | Error, instance: any, trace: string) => {
    const context = {
      component: instance?.$options?.name || 'Unknown Component',
      trace,
    };
    logMessage('warning', 'Vue', message, context);
    reportMessage(
      message,
      {
        warningType: 'vue',
        ...context,
      },
      'warning'
    );
  });

  /**
   * @see https://nuxt.com/docs/getting-started/error-handling#startup-errors
   */
  nuxtApp.hook('app:error', (error: Error) => {
    logMessage('error', 'App', error);
    reportMessage(
      error,
      {
        errorType: 'app',
      },
      'error'
    );
  });

  nuxtApp.hook('app:warn', (warning: Error | string) => {
    logMessage('warning', 'App', warning);
    reportMessage(
      warning,
      {
        warningType: 'app',
      },
      'warning'
    );
  });

  /**
   * @see https://nuxt.com/docs/getting-started/error-handling#server-side-rendering-errors
   */
  nuxtApp.vueApp.config.errorHandler = (error: Error, instance: any, info: string) => {
    const context = {
      component: instance?.$options?.name || 'Unknown Component',
      info,
    };
    logMessage('error', 'SSR', error, context);
    reportMessage(
      error,
      {
        errorType: 'ssr',
        ...context,
      },
      'error'
    );
  };

  nuxtApp.vueApp.config.warnHandler = (message: string, instance: any, trace: string) => {
    const context = {
      component: instance?.$options?.name || 'Unknown Component',
      trace,
    };
    logMessage('warning', 'SSR', message, context);
    reportMessage(
      message,
      {
        warningType: 'ssr',
        ...context,
      },
      'warning'
    );
  };

  /**
   * @see https://nuxt.com/docs/getting-started/error-handling#unhandled-promise-rejections
   */
  if (isClient) {
    window.addEventListener('unhandledrejection', (event) => {
      logMessage('error', 'Unhandled Promise Rejection', event.reason);
      reportMessage(
        event.reason,
        {
          errorType: 'unhandledRejection',
        },
        'error'
      );
    });
  }
});
