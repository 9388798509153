import type { HousingProperty, HousingPropertyGroup, HousingType, Room, PropertyCategory, FeatureList } from '~/models/Raws';

type Statistics = {
  matches: number;
  interested: number;
  views: number;
  liked: number;
  conversations: number;
};

export interface BaseState {
  isMobile: boolean;
  layout: 'default' | 'home' | 'dashboard' | 'chat' | 'onboarding';

  monthlyDefaultPrice: number;
  trialPeriodDurationInDays: number;
  trialPeriodDurationInDaysRemaining: number;

  countOfUnseenConversations: number;

  rooms: Room[];
  housingTypes: HousingType[];
  housingPropertyGroups: HousingPropertyGroup[];
  housingPropertyGroupTypes: PropertyCategory[];
  properties: HousingProperty[];
  featureList: FeatureList | undefined;
  initializedAt: Date | undefined;
}

export const useBaseStore = defineStore('base', () => {
  const { dashboardAPI } = useBackendRequests();

  const state = ref<BaseState>({
    isMobile: false,
    layout: 'default',

    monthlyDefaultPrice: 0,
    trialPeriodDurationInDays: 0,
    trialPeriodDurationInDaysRemaining: 0,

    countOfUnseenConversations: 0,

    rooms: [],
    housingTypes: [],
    housingPropertyGroups: [],
    housingPropertyGroupTypes: [],
    properties: [],
    featureList: undefined,
    initializedAt: undefined,
  });

  const statistics = ref<Statistics>({
    matches: 0,
    interested: 0,
    views: 0,
    liked: 0,
    conversations: 0,
  });

  async function updateStatistics() {
    const response = await dashboardAPI.getStatistics();
    if (response.status === 'ok') {
      statistics.value.matches = +response.data.numberOfMatches || 0;
      statistics.value.interested = +response.data.numberOfInterestedUsers || 0;
      statistics.value.views = +response.data.numberOfViews || 0;
      statistics.value.liked = +response.data.numberOfWishlistItems || 0;
      statistics.value.conversations = +response.data.numberOfConversations || 0;
    } else {
      statistics.value.matches = Math.round(Math.random() * 5);
      statistics.value.interested = Math.round(Math.random() * 5);
      statistics.value.views = Math.round(Math.random() * 5);
      statistics.value.liked = Math.round(Math.random() * 5);
      statistics.value.conversations = Math.round(Math.random() * 5);
    }
  }

  return {
    state,
    statistics,
    updateStatistics,
  };
});

export default useBaseStore;
