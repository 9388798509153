export default defineNuxtPlugin(() => {
    addRouteMiddleware
        ('global-update-app', async () => {
            if (import.meta.server) {
                return
            }

            const { checkAppVersion, appUpdateAvailable } = useAppVersion();

            await checkAppVersion();

            if (!appUpdateAvailable.value) {
                return;
            }

            reloadNuxtApp({ force: true });

        }, { global: true })
})