import type { BackendFetchOptions } from '~/modules/backend/composables/use-backend-requests';
import type AccountSettingsNotifications from '~/models/AccountSettingsNotifications';
import type AccountConversationUsage from '~/models/AccountConversationUsage';
import type { ActiveHousingExport, HousingImage } from '~/models/Housing';
import type AccountSettingsExports from '~/models/AccountSettingsExports';
import type AccountActiveProduct from '~/models/AccountActiveProduct';
import type AccountSubscription from '~/models/AccountSubscription';
import type AccountStatistics from '~/models/AccountStatistics';
import type AccountUser from '~/models/AccountUser';
import type Housing from '~/models/Housing';
import type Search from '~/models/Search';

import type { AxiosRequestConfig } from 'axios';

type RequestStatus = 'idle' | 'pending' | 'success' | 'error';

export const useAccountStore = defineStore('account', () => {
  const { accountAPI, chatAPI, dashboardAPI, housingAPI, userAPI } = useBackendRequests();

  // USER
  const user = ref<AccountUser>();

  const userHasLimitedAccess = computed(() => user.value?.role?.id === 'new-limited-access');

  function clearUser() {
    user.value = undefined;
  }

  function updateUserEmail(email: string) {
    return userAPI.updateEmail(email).finally(() => {
      executeUserSearchHousingRequest(true);
    });
  }

  function updateUserPassword(currentPassword: string, newPassword: string, newPasswordConfirmed: string) {
    return userAPI.updatePassword(currentPassword, newPassword, newPasswordConfirmed);
  }

  function updateUserNotificationSettings(values: AccountSettingsNotifications) {
    return userAPI.updateNotifications(values).then((response) => {
      user.value = response.data.loggedInUser;
    });
  }

  function deleteAccount(reason: string, additionalInfo: string) {
    return accountAPI.delete(reason, additionalInfo).then(() => {
      clear();
    });
  }

  // SEARCH
  const search = ref<Search>();

  function clearSearch() {
    search.value = undefined;
  }

  // HOUSING
  const housing = ref<Housing>();

  const housingImages = computed(() => housing.value?.imageCollection.images || []);

  const activeHousingExports = computed(() =>
    housing.value?.exportInfo.activeExports ? housing.value.exportInfo.activeExports.filter((item: ActiveHousingExport) => item.url) : []
  );

  const housingMeetsExportQualityCriteria = computed(() => housing.value?.exportInfo?.meetsQualityCriteria ?? false);

  const housingIsGenerallyExportable = computed(() => housing.value?.exportInfo?.isGenerallyExportable ?? false);

  function clearHousing() {
    housing.value = undefined;
  }

  function uploadHousingImage(file: File, options?: AxiosRequestConfig) {
    return housingAPI.uploadImage(file, options);
  }

  function deleteHousingImage(imageId: number) {
    return housingAPI.deleteImage(imageId).then((response) => {
      executeUserSearchHousingRequest(true);
      return response;
    });
  }

  function updateHousingImageOrder(updatedImages: HousingImage[]) {
    return housingAPI.orderImages(updatedImages).then((response) => {
      executeUserSearchHousingRequest(true);
      return response;
    });
  }

  const housingIsActive = computed(() => !!housing.value?.isActive);

  // BASE DATA
  const userSearchHousingRequestStatus = ref<RequestStatus>('idle');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const userSearchHousingRequestError = ref<any>();
  const userSearchHousingRequestLastExecutedAt = ref<Date>();
  const userSearchHousingHasActiveCache = computed(
    () => user.value && search.value && housing.value && !cacheLifetimeExpired(userSearchHousingRequestLastExecutedAt.value)
  );

  async function executeUserSearchHousingRequest(force = false, options?: BackendFetchOptions) {
    if (!force && userSearchHousingHasActiveCache.value) {
      return true;
    }

    userSearchHousingRequestStatus.value = 'pending';

    try {
      const { data } = await housingAPI.getForCurrentUser(options);
      housing.value = data.housing;
      search.value = data.search;
      user.value = data.user;
      userSearchHousingRequestStatus.value = 'success';
      userSearchHousingRequestLastExecutedAt.value = new Date();
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      userSearchHousingRequestError.value = error;
      userSearchHousingRequestStatus.value = 'error';
      return false;
    }
  }

  function clearUserSearchHousing() {
    clearUser();
    clearSearch();
    clearHousing();
    userSearchHousingRequestStatus.value = 'pending';
    userSearchHousingRequestError.value = undefined;
    userSearchHousingRequestLastExecutedAt.value = undefined;
  }

  function updateExportSettings(values: AccountSettingsExports) {
    userAPI.updateExports(values).then((response) => {
      user.value = response.data.loggedInUser;
    });
  }

  // STATISTICS
  const statistics = ref<AccountStatistics>();
  const statisticsRequestStatus = ref<RequestStatus>('idle');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const statisticsRequestError = ref<any>();
  const statisticsRequestLastExecutedAt = ref<Date>();
  const statisticsHasActiveCache = computed(() => statistics.value && !cacheLifetimeExpired(statisticsRequestLastExecutedAt.value));

  async function executeStatisticsRequest(force = false, options?: BackendFetchOptions) {
    if (!force && statisticsHasActiveCache.value) {
      return true;
    }

    statisticsRequestStatus.value = 'pending';

    try {
      const response = await dashboardAPI.getStatistics(options);
      statistics.value = response.data ?? undefined;
      statisticsRequestStatus.value = 'success';
      statisticsRequestLastExecutedAt.value = new Date();
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      statisticsRequestError.value = error;
      statisticsRequestStatus.value = 'error';
      return false;
    }
  }

  function clearStatistics() {
    statistics.value = undefined;
    statisticsRequestStatus.value = 'idle';
    statisticsRequestError.value = undefined;
    statisticsRequestLastExecutedAt.value = undefined;
  }

  // SUBSCRIPTION
  const subscription = ref<AccountSubscription>();
  const subscriptionRequestStatus = ref<RequestStatus>('idle');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const subscriptionRequestError = ref<any>();
  const subscriptionRequestLastExecutedAt = ref<Date>();
  const subscriptionHasActiveCache = computed(() => subscription.value && !cacheLifetimeExpired(subscriptionRequestLastExecutedAt.value));

  const currentSubscriptionPeriod = computed(() => subscription.value?.periods[subscription.value?.periods.length - 1]);

  const canWithdrawNoticeOfTermination = computed(() => !!subscription.value?.cancellationDate?.date);

  async function executeSubscriptionRequest(force = false, options?: BackendFetchOptions) {
    if (!force && subscriptionHasActiveCache.value) {
      return true;
    }

    subscriptionRequestStatus.value = 'pending';

    try {
      const response = await accountAPI.getCurrentSubscription(options);
      subscription.value = response.subscription ?? undefined;
      subscriptionRequestStatus.value = 'success';
      subscriptionRequestLastExecutedAt.value = new Date();
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      subscriptionRequestError.value = error;
      subscriptionRequestStatus.value = 'error';
      return false;
    }
  }

  function clearSubscription() {
    subscription.value = undefined;
    subscriptionRequestStatus.value = 'idle';
    subscriptionRequestError.value = undefined;
    subscriptionRequestLastExecutedAt.value = undefined;
  }

  function cancelCurrentSubscription() {
    return accountAPI.cancelCurrentSubscription().finally(() => {
      executeSubscriptionRequest(true);
    });
  }

  function withdrawNoticeOfSubscriptionTermination() {
    return accountAPI.withdrawNoticeOfTermination().finally(() => {
      executeSubscriptionRequest(true);
    });
  }

  // ACTIVE PRODUCTS
  const activeProducts = ref<AccountActiveProduct[]>();
  const activeProductsRequestStatus = ref<RequestStatus>('idle');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const activeProductsRequestError = ref<any>();
  const activeProductsRequestLastExecutedAt = ref<Date>();
  const activeProductsHasActiveCache = computed(() => activeProducts.value && !cacheLifetimeExpired(activeProductsRequestLastExecutedAt.value));

  const multiPortalPublishingUpsell = computed(() =>
    activeProducts.value ? activeProducts.value?.filter((product) => product.key === 'multi-portal-publishing') : []
  );

  const hasMultiPortalPublishingUpsell = computed(() => (multiPortalPublishingUpsell.value ? multiPortalPublishingUpsell.value.length > 0 : false));

  async function executeActiveProductsRequest(force = false, options?: BackendFetchOptions) {
    if (!force && activeProductsHasActiveCache.value) {
      return true;
    }

    activeProductsRequestStatus.value = 'pending';

    try {
      const response = await accountAPI.getActiveProducts(options);
      activeProducts.value = response ?? undefined;
      activeProductsRequestStatus.value = 'success';
      activeProductsRequestLastExecutedAt.value = new Date();
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      activeProductsRequestError.value = error;
      activeProductsRequestStatus.value = 'error';
      return false;
    }
  }

  function clearActiveProducts() {
    activeProducts.value = undefined;
    activeProductsRequestStatus.value = 'idle';
    activeProductsRequestError.value = undefined;
    activeProductsRequestLastExecutedAt.value = undefined;
  }

  // CONVERSATION CONFIG
  const conversationConfig = ref<AccountConversationUsage>();
  const conversationConfigRequestStatus = ref<RequestStatus>('idle');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const conversationConfigRequestError = ref<any>();
  const conversationConfigRequestLastExecutedAt = ref<Date>();
  const conversationConfigHasActiveCache = computed(() => conversationConfig.value && !cacheLifetimeExpired(conversationConfigRequestLastExecutedAt.value));

  async function executeConversationConfigRequest(force = false, options?: BackendFetchOptions) {
    if (!force && conversationConfigHasActiveCache.value) {
      return true;
    }

    conversationConfigRequestStatus.value = 'pending';

    try {
      const response = await chatAPI.getConversationUsage(options);
      conversationConfig.value = response.data.conversationUsage ?? undefined;
      conversationConfigRequestStatus.value = 'success';
      conversationConfigRequestLastExecutedAt.value = new Date();
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      conversationConfigRequestError.value = error;
      conversationConfigRequestStatus.value = 'error';
      return false;
    }
  }

  function clearConversationConfig() {
    conversationConfig.value = undefined;
    conversationConfigRequestStatus.value = 'idle';
    conversationConfigRequestError.value = undefined;
    conversationConfigRequestLastExecutedAt.value = undefined;
  }

  function clear() {
    clearStatistics();
    clearSubscription();
    clearActiveProducts();
    clearUserSearchHousing();
    clearConversationConfig();
  }

  function loadData(force = false, options?: BackendFetchOptions) {
    return Promise.all([
      executeStatisticsRequest(force, options),
      executeSubscriptionRequest(force, options),
      executeActiveProductsRequest(force, options),
      executeUserSearchHousingRequest(force, options),
      executeConversationConfigRequest(force, options),
    ]).then((response) => {
      initializedAt.value = new Date();
      return response;
    });
  }

  const isLoading = computed(
    () =>
      statisticsRequestStatus.value === 'pending' ||
      subscriptionRequestStatus.value === 'pending' ||
      activeProductsRequestStatus.value === 'pending' ||
      userSearchHousingRequestStatus.value === 'pending' ||
      conversationConfigRequestStatus.value === 'pending'
  );

  /**
   * Write this value only once after initially loading all data with
   * "loadData()", because the global guard checks for this.
   */
  const initializedAt = ref<Date>();

  return {
    // USER
    user,
    userHasLimitedAccess,
    clearUser,
    updateUserEmail,
    updateUserPassword,
    updateUserNotificationSettings,
    deleteAccount,

    // SEARCH
    search,
    clearSearch,

    // HOUSING
    housing,
    housingImages,
    activeHousingExports,
    housingMeetsExportQualityCriteria,
    housingIsGenerallyExportable,
    clearHousing,
    housingIsActive,
    uploadHousingImage,
    deleteHousingImage,
    updateHousingImageOrder,

    // BASE DATA
    userSearchHousingRequestStatus,
    userSearchHousingRequestError,
    userSearchHousingRequestLastExecutedAt,
    executeUserSearchHousingRequest,
    clearUserSearchHousing,
    updateExportSettings,

    // STATISTICS
    statistics,
    statisticsRequestStatus,
    statisticsRequestError,
    statisticsRequestLastExecutedAt,
    executeStatisticsRequest,
    clearStatistics,

    // SUBSCRIPTION
    subscription,
    subscriptionRequestStatus,
    subscriptionRequestError,
    subscriptionRequestLastExecutedAt,
    canWithdrawNoticeOfTermination,
    currentSubscriptionPeriod,
    executeSubscriptionRequest,
    clearSubscription,
    cancelCurrentSubscription,
    withdrawNoticeOfSubscriptionTermination,

    // ACTIVE PRODUCTS
    activeProducts,
    activeProductsRequestStatus,
    activeProductsRequestError,
    activeProductsRequestLastExecutedAt,
    multiPortalPublishingUpsell,
    hasMultiPortalPublishingUpsell,
    executeActiveProductsRequest,
    clearActiveProducts,

    // CONVERSATION CONFIG
    conversationConfig,
    conversationConfigRequestStatus,
    conversationConfigRequestError,
    conversationConfigRequestLastExecutedAt,
    executeConversationConfigRequest,
    clearConversationConfig,
    isLoading,
    clear,
    loadData,
    initializedAt,
  };
});

export default useAccountStore;
