<script lang="ts" setup>
const { t } = useI18n({
  useScope: 'local',
});
</script>

<template>
  <div>
    <i18n-t keypath="text">
      <template #cta>
        <TwUiAction to="/account/settings/credentials" :is="'link'" :variant="'text'">
          {{ t('cta') }}
        </TwUiAction>
      </template>
    </i18n-t>
  </div>
</template>

<style></style>

<i18n lang="json">
{
  "de": {
    "text": "Um dich besser zu schützen, haben wir neue Passwortrichtlinien eingeführt. Bitte aktualisiere dein Passwort. {cta}",
    "cta": "Passwort jetzt ändern"
  },
  "en": {
    "text": "We have introduced new password guidelines to better protect you. Please update your password. {cta}",
    "cta": "Change password now"
  }
}
</i18n>
