import type { ChatListItem, ChatMessage, ChatResponse, ChatPostMessage } from '@/models/ChatData';
import type  AccountConversationUsage from '@/models/AccountConversationUsage';
import type { AccountConversationConfigResponse } from '@/modules/backend/types';
import { defineStore } from 'pinia';
import { useUnseenConversations } from '@/composables/use-unseen-conversations';

export const useChatStore = defineStore('chat', () => {
  const { getUnseenConversations } = useUnseenConversations();
  const { chatAPI } = useBackendRequests();

  const newMessage = ref<string>();
  const isSending = ref<boolean>();
  const filterStatus = ref<string>();
  const responseRate = ref<{ rate: number; display: string }>({ rate: 0, display: '' });
  const responseTime = ref<string>();
  const scrollPosition = ref<number>();
  const olderMessagesExist = ref<boolean>();
  const activeChatRoom = ref<ChatListItem>();
  const chatMessages = ref<ChatMessage[]>([]);
  const chatListItems = ref<ChatListItem[]>([]);
  const isLoadingOlderMessages = ref<boolean>();
  const unsentMessages = ref<{ roomId: number; newMessage: string | undefined }[]>([]);
  const conversationUsage = ref<AccountConversationUsage | null>(null);
  const statusCounts = ref<{ active: number; archived: number, firstContact: number, fullInterest: number, unread: number }>({ active: 0, archived: 0, firstContact: 0, fullInterest: 0, unread: 0 });
  const initializedAt = ref();
  const loadingData = ref(false);

  const latestMessage = computed(() => {
    return [...chatMessages.value].sort((a, b) => b.id - a.id)[0];
  });

  const latestMessageId = computed(() => {
    return latestMessage.value?.id;
  });

  const oldestMessage = computed(() => {
    return [...chatMessages.value].sort((a, b) => a.id - b.id)[0];
  });

  const oldestMessageId = computed(() => {
    return oldestMessage.value?.id;
  });

  function getDisplayNameByGuid(guid: string) {
    if (!activeChatRoom.value) {
      return null;
    }

    if (activeChatRoom.value.targetUser?.guid === guid) {
      return activeChatRoom.value.targetUser?.displayName;
    }

    if (activeChatRoom.value.intermediateUser?.guid === guid) {
      return activeChatRoom.value.intermediateUser.displayName;
    }

    return null;
  }

  function getImageByGuid(guid: string) {
    if (!activeChatRoom.value) {
      return null;
    }

    if (activeChatRoom.value.targetUser?.guid === guid) {
      return activeChatRoom.value.targetUser.image?.urlPaths['50x50'] || null;
    }

    if (activeChatRoom.value.intermediateUser?.guid === guid) {
      return activeChatRoom.value.intermediateUser.image?.urlPaths['50x50'] || null;
    }

    return null;
  }

  function setChatListItems(newItems: ChatListItem[]) {
    chatListItems.value = newItems;
  }

  function appendChatListItems(newItems: ChatListItem[]) {
    const filteredItems = newItems.filter(newItem => {
      return !chatListItems.value.some(existingItem => existingItem.chatRoom.id === newItem.chatRoom.id);
    });
    chatListItems.value = [...chatListItems.value, ...filteredItems];
  }

  function clearChatListItems() {
    chatListItems.value = [];
  }

  async function sendMessage(data: ChatPostMessage, myGuid: string): Promise<ChatResponse> {
    try {
      const roomId = activeChatRoom.value?.chatRoom?.id || data.roomId;
      if (typeof roomId === 'number' && !isNaN(roomId)) {
        removeFirstContactFromList(roomId);
      }
      const response = await chatAPI.sendMessage(data) as ChatResponse;
      if (activeChatRoom.value?.isFirstContact) {
        getChatResponseTimes(myGuid);
        activeChatRoom.value.isFirstContact = false;
      }
      return response;
    } catch (error: any) {
      return error.response?._data?.message || 'could_not_send_message'
    }
  }

  function removeFirstContactFromList(roomId: number) {
    if (filterStatus.value === 'firstContact') {
      chatListItems.value = chatListItems.value.filter((item) => !(item.chatRoom.id === roomId && item.isFirstContact === false));
    }
  }
  async function markChatListItemAsUnread(roomId: number) {
    try {
      updateMarkedAsUnread(roomId, true);
      const response = await chatAPI.markRoomAsUnreadByRoomId(roomId);
      return response;
    } catch (error) {
      updateMarkedAsUnread(roomId, false);
      console.log(error);
      return false;
    }
  }
  function updateMarkedAsUnread(chatRoomId: number, markedAsUnread: boolean) {
    const chatIndex = chatListItems.value.findIndex((item) => item.chatRoom?.id === chatRoomId);
    if (chatIndex !== -1) {
      chatListItems.value[chatIndex].markedAsUnread = markedAsUnread;
      chatListItems.value = [...chatListItems.value];
    } else {
      console.warn(`updateMarkedAsUnread ${chatRoomId} not found`);
    }
  }
  function archiveChatRoom(roomId: number) {
    return chatAPI.archiveByRoomId(roomId);
  }
  function restoreChatRoom(roomId: number) {
    return chatAPI.restoreByRoomId(roomId);
  }
  async function getOlderMessages(roomId: number, oldestMessageId?: number) {
    try {
      isLoadingOlderMessages.value = true;
      const response = await chatAPI.getOlderMessages(roomId, oldestMessageId);
      const newMessages = response.messages || [];
      await getUnseenConversations();
      setMarkedAsRead(roomId);

      if (typeof oldestMessageId === 'number' && !isNaN(oldestMessageId)) {
        chatMessages.value = [...newMessages, ...(chatMessages.value || [])];
      } else {
        chatMessages.value = newMessages;
      }
      olderMessagesExist.value = response.olderMessagesExist ?? false;
      return true;
    } catch (error) {
      console.error('Error get older messages: ', error);
      return false;
    }
    finally {
        isLoadingOlderMessages.value = false;
    }
  }
  function setMarkedAsRead(roomId: number) {
    const index = chatListItems.value.findIndex((item) => item.chatRoom.id === roomId);
    if (
      activeChatRoom.value &&
      activeChatRoom.value.chatRoom.id === roomId &&
      (activeChatRoom.value.markedAsUnread || activeChatRoom.value.numberOfUnreadMessages > 0)
    ) {
      activeChatRoom.value.markedAsUnread = false;
      activeChatRoom.value.numberOfUnreadMessages = 0;
    }

    if (index !== -1 && chatListItems.value[index]!.numberOfUnreadMessages > 0) {
      chatListItems.value[index] = {
        ...chatListItems.value[index],
        markedAsUnread: false,
        numberOfUnreadMessages: 0,
      };
    }

    if (filterStatus.value === 'unread') {
      chatListItems.value = chatListItems.value.filter(
        (item) => !(item.chatRoom.id === roomId && item.markedAsUnread === false && item.numberOfUnreadMessages === 0)
      );
    }
  }
  async function getNewestMessages(roomId: number, newestMessageId: number) {
    try {
      const response = await chatAPI.getNewerMessages(roomId, newestMessageId ?? 0);
      const newMessages = response.newMessages || [];
      if (newMessages.length > 0) {
        addNewMessages(newMessages);
      }
      return newMessages;
    } catch (error) {
      console.error('Error get newest messages: ', error);
      return [];
    }
  }
  function addNewMessages(newMessages: ChatMessage[]) {
    chatMessages.value = [...chatMessages.value, ...newMessages];
    const newestMessage = latestMessage.value;
    if (activeChatRoom.value && newestMessage) {
      activeChatRoom.value.lastMessage = newestMessage;
    }
  }
  async function getChatListItem(targetUserGuid: string, intermediateUserGuid?: string) {
    try {
      const response = await chatAPI.getItem(targetUserGuid, intermediateUserGuid);
      const item = response.data.chatListItem;

      if (item) {
        const existingItemIndex = chatListItems.value.findIndex((chatItem) => chatItem.chatRoom.id === item.chatRoom.id);
        if (existingItemIndex !== -1) {
          const existingItem = chatListItems.value.splice(existingItemIndex, 1)[0];
          if (existingItem) {
            chatListItems.value = [existingItem, ...chatListItems.value];
          }
        } else {
          chatListItems.value = [item, ...chatListItems.value];
        }
        activeChatRoom.value = item;
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error getting chat list item: ', error);
      return false;
    }
  }
  function setScrollPosition(position: number) {
    scrollPosition.value = position;
  }
  function scrollRoomListTop() {
    if (!import.meta.env.SSR) {
      const element = document.querySelector('.room-list') as HTMLElement | null;
      if (element) {
        element.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  }
  function updateUnsentMessages(roomId: number, message: string | undefined) {
    if (message && !message.trim()) {
      clearUnsentMessage(roomId);
      return;
    }
    const existingEntry = unsentMessages.value?.find((item) => item.roomId === roomId);
    if (existingEntry) {
      existingEntry.newMessage = message ?? '';
    } else {
      unsentMessages.value?.push({ roomId, newMessage: message });
    }
  }
  function clearUnsentMessage(roomId: number) {
    unsentMessages.value = unsentMessages.value?.filter((item) => item.roomId !== roomId);
  }
  function getUnsentMessage(roomId: number) {
    const entry = unsentMessages.value?.find((item) => item.roomId === roomId);
    return entry ? entry.newMessage : '';
  }
  function updateRoom(chatListItem?: ChatListItem, passedTargetUserGuid?: string, passedIntermediateUserGuid?: string): void {
    const targetUserGuid = chatListItem?.targetUser?.guid || passedTargetUserGuid;
    const intermediateUserGuid = chatListItem?.intermediateUser?.guid || passedIntermediateUserGuid;

    if (!targetUserGuid) {
      console.error('No targetUserGuid provided');
      return;
    }
    const newUrl = intermediateUserGuid ? `/messaging/chat/${targetUserGuid}/${intermediateUserGuid}` : `/messaging/chat/${targetUserGuid}`;

    if (import.meta.client) {
      window.history.replaceState(null, '', newUrl);
    }
    if (chatListItem) {
      activeChatRoom.value = chatListItem;
    }
  }
  function setUrlToDashboard(): void {
    if (import.meta.client) {
      window.history.replaceState(null, '', '/messaging/dashboard');
    }
  }
  async function loadChatListItems({ status, query, page, perPage }: { status: string; query: string; page: number; perPage: number }) {
    const response = await chatAPI.getList(status, query, page, perPage);
    setChatListItems(response.data.chatListItems);
    return response.data.chatListItems;
  }
  async function getConversationUsage(): Promise<AccountConversationConfigResponse> {
    try {
      const response = await chatAPI.getConversationUsage();

      if (response) {
        conversationUsage.value = response.data.conversationUsage;
      }
      return response;
    } catch (error) {
      console.error('Error in getConversationUsage:', error);
      throw error;
    }
  }
  async function getChatResponseTimes(myGuid: string) {
    try {
      loadingData.value = true;
      const result = await chatAPI.getResponseTimeAndRate(myGuid);
      responseRate.value = {
        rate: result.data.responseRate.rate,
        display: result.data.responseRate.display
      };
      responseTime.value = result.data.responseTime.display;
    } catch (error) {
      console.error('Error get chat response times: ', error);
    } finally {
        loadingData.value = false;
    }
  }
  async function getStatusCounts(useLoadingState = true) {
    try {
      if (useLoadingState) loadingData.value = true;
      const { data: { active, archived, firstContact, fullInterest, unread } } = await chatAPI.getStatusCounts();
      statusCounts.value.active = active;
      statusCounts.value.archived = archived;
      statusCounts.value.firstContact = firstContact;
      statusCounts.value.fullInterest = fullInterest;
      statusCounts.value.unread = unread;
    } catch (error) {
      console.error('Error getting chat status counts:', error);
    } finally {
      if (useLoadingState) loadingData.value = false;
    }
  }


  return {
    newMessage,
    unsentMessages,
    filterStatus,
    activeChatRoom,
    chatMessages,
    chatListItems,
    olderMessagesExist,
    responseRate,
    responseTime,
    scrollPosition,
    isSending,
    isLoadingOlderMessages,
    loadingData,
    latestMessage,
    latestMessageId,
    oldestMessage,
    oldestMessageId,
    conversationUsage,
    statusCounts,
    initializedAt,
    getDisplayNameByGuid,
    getImageByGuid,
    setChatListItems,
    appendChatListItems,
    clearChatListItems,
    sendMessage,
    removeFirstContactFromList,
    markChatListItemAsUnread,
    updateMarkedAsUnread,
    archiveChatRoom,
    restoreChatRoom,
    getOlderMessages,
    setMarkedAsRead,
    getNewestMessages,
    addNewMessages,
    getChatListItem,
    setScrollPosition,
    scrollRoomListTop,
    updateUnsentMessages,
    clearUnsentMessage,
    getUnsentMessage,
    updateRoom,
    setUrlToDashboard,
    loadChatListItems,
    getConversationUsage,
    getChatResponseTimes,
    getStatusCounts,
  };
});

export default useChatStore;
