<script setup lang="ts">
const system = useSystemStore();
const isClient = ref(false);

const closeBanner = (id: string) => {
  system.removeMessage(id);
};

onMounted(() => {
  isClient.value = true;
});
</script>

<template>
  <ul v-if="isClient && system.banners.length > 0">
    <li v-for="banner in system.banners" :key="banner.id">
      <div><tw-dynamic-content :content="banner.content" :componentProps="banner.props" /></div>
      <button class="close-button" @click="closeBanner(banner.id)">
        <Icon name="bi:x" class="close-button-icon" />
      </button>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/tw-variables.scss';

ul {
  width: 100%;
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $lightGray;
}

li {
  gap: 1rem;
  display: flex;
  color: #856404;
  font-weight: normal;
  align-items: center;
  border-color: #ffeeba;
  padding: 0.75rem 1.25rem;
  background-color: #fff3cd;
  justify-content: space-between;
}

.close-button {
  padding: 0;
  border: none;
  cursor: pointer;
  background: none;
}

.close-button:hover .close-button-icon {
  color: $primary;
}

.close-button-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #856404;
}
</style>
