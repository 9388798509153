/**
 * General function to check a given Date against a statically defined cache lifetime
 *
 * @param lastFetchedAt Date
 * @param cacheLifetime Number
 * @returns Boolean
 */
export default function (lastFetchedAt?: Date, cacheLifetime = 1000 * 60 * 10): boolean {
  if (!lastFetchedAt) {
    return true;
  }

  return Date.now() - lastFetchedAt.getTime() > (cacheLifetime ?? 1000 * 60 * 10);
}
