import revive_payload_client_noIJYTa3grkA2uamqHHmaZhBvm38j_hJuZJybHNw7KA from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__ioredis@5.6.0_mag_28160870fc8669f4f8b097b6722ee748/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5hVoC1vMVhRtu7VPkfl7_oz1DYq6O5aHdgiVd9W_tX4 from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__ioredis@5.6.0_mag_28160870fc8669f4f8b097b6722ee748/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_1ZwgmfAPnBKEI5wNwCpUAY5gLGD75I9Lwsey3qb_kZk from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__ioredis@5.6.0_mag_28160870fc8669f4f8b097b6722ee748/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import addUpdateAppMiddleware_pNQfc_CKf9eMyBMvXV_pZIy92TnMZg6SkhmuLriPXjg from "/app/layers/app-version/plugins/addUpdateAppMiddleware.ts";
import payload_client_IEThp6JSpoaT8aKKfMJ52XDk11Cf188egzXHliqhV50 from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__ioredis@5.6.0_mag_28160870fc8669f4f8b097b6722ee748/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_d0qiO_NUd1HJJwFmgEb6AALx0QAvwNq8_uumxepgt0I from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__ioredis@5.6.0_mag_28160870fc8669f4f8b097b6722ee748/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_11Tv1j9RP1pCXfHLM4ttLyvTi_FE2Kz82Aae3fPTw3s from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__ioredis@5.6.0_mag_28160870fc8669f4f8b097b6722ee748/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_WOwUwHaiwq5piBNX418dkaXox5MJg0udVqfALEqbatM from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__ioredis@5.6.0_mag_28160870fc8669f4f8b097b6722ee748/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_k48GGbVo3fR_HT5QOAfgRztWo1RcNDPHhZW3oIYrJ4g from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/browser/.nuxt/components.plugin.mjs";
import prefetch_client_fTKgj2O3zQ9CiE78puZZusdYn1wAf7XMyeCaylsxT_M from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__ioredis@5.6.0_mag_28160870fc8669f4f8b097b6722ee748/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_A3XH51oR5kKhSgkBc_8ynPZPJ7AnhQGWfO9nmGCRXco from "/app/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.3_jiti@2.4.2_sass@1.75.0_terser@5.39.0_yaml@2_ec1ec630349b559e7250671ef3a80c20/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import nuxt_plugin_5aK8FQ54r8bk26Bt8pYNH1GPDq4_Bs24PTDwjeN4KRs from "/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_client_xryMBT5jK3LuAu16GanEeFzU5OU3nz_3fPiRPbM_GRU from "/app/node_modules/.pnpm/nuxt-viewport@2.2.0_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_WQW8AhN8NnIy8AHeQQPi_QXn6XTQ4NysJ_8Wm1sEVOU from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_d4e6ed4c4c984d50edef16092531f64f/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_QdJuMtQdKdllLZuHUUaypuui_VulTE14GT7uJOHxrB8 from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_d4e6ed4c4c984d50edef16092531f64f/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_heVS25PKYA0BVmZ7xqW6iXOhG3q7yW0VLivzQ7hLKTo from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_d4e6ed4c4c984d50edef16092531f64f/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import axios_lmVes6wTUN_JB9KC_8jN_3zXMzxtYJj_fc2XEgZ32n4 from "/app/browser/modules/backend/plugins/axios.ts";
import fetch_whSL_l_sFihy9s_uNMyt_RmUhqmzWFaBUMIun_PLX4c from "/app/browser/modules/backend/plugins/fetch.ts";
import seo_meta_58ZqtB_K1IuilVeNc7eYktW7x1RmlqK_4MdNhuJXZb8 from "/app/browser/plugins/seo-meta.ts";
import marketing_tracking_P3cd25rIWXjymUzDBdWxKme6aT_iCSqmoAQdCd6CZgs from "/app/browser/plugins/marketing-tracking.ts";
import error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU from "/app/browser/plugins/error-handler.ts";
import vue_datepicker_ZxbWd_XFJcMflv9_PwGgUvI_g6wpTHJLSRNAJm1TIzo from "/app/browser/plugins/vue-datepicker.ts";
import vue_google_tag_manager_client_zMJHJk_b5C9dZESreNMV3sEknkhrkVigOD8tWPDgeXw from "/app/browser/plugins/vue-google-tag-manager.client.ts";
import plugin_client_lxWKvJVWQ7nxykTLpu__093p6rdFQhYd_ZKYo3UMtbc from "/app/browser/.nuxt/nuxt-booster/plugin.client.js";
import ssg_detect_EZ9YuvzaydiaKxf5P50MldBhBrZB_zoyUvemA4ROKkU from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_d4e6ed4c4c984d50edef16092531f64f/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_noIJYTa3grkA2uamqHHmaZhBvm38j_hJuZJybHNw7KA,
  unhead_5hVoC1vMVhRtu7VPkfl7_oz1DYq6O5aHdgiVd9W_tX4,
  router_1ZwgmfAPnBKEI5wNwCpUAY5gLGD75I9Lwsey3qb_kZk,
  addUpdateAppMiddleware_pNQfc_CKf9eMyBMvXV_pZIy92TnMZg6SkhmuLriPXjg,
  payload_client_IEThp6JSpoaT8aKKfMJ52XDk11Cf188egzXHliqhV50,
  navigation_repaint_client_d0qiO_NUd1HJJwFmgEb6AALx0QAvwNq8_uumxepgt0I,
  check_outdated_build_client_11Tv1j9RP1pCXfHLM4ttLyvTi_FE2Kz82Aae3fPTw3s,
  chunk_reload_client_WOwUwHaiwq5piBNX418dkaXox5MJg0udVqfALEqbatM,
  plugin_vue3_k48GGbVo3fR_HT5QOAfgRztWo1RcNDPHhZW3oIYrJ4g,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_fTKgj2O3zQ9CiE78puZZusdYn1wAf7XMyeCaylsxT_M,
  plugin_A3XH51oR5kKhSgkBc_8ynPZPJ7AnhQGWfO9nmGCRXco,
  nuxt_plugin_5aK8FQ54r8bk26Bt8pYNH1GPDq4_Bs24PTDwjeN4KRs,
  plugin_client_xryMBT5jK3LuAu16GanEeFzU5OU3nz_3fPiRPbM_GRU,
  switch_locale_path_ssr_WQW8AhN8NnIy8AHeQQPi_QXn6XTQ4NysJ_8Wm1sEVOU,
  route_locale_detect_QdJuMtQdKdllLZuHUUaypuui_VulTE14GT7uJOHxrB8,
  i18n_heVS25PKYA0BVmZ7xqW6iXOhG3q7yW0VLivzQ7hLKTo,
  axios_lmVes6wTUN_JB9KC_8jN_3zXMzxtYJj_fc2XEgZ32n4,
  fetch_whSL_l_sFihy9s_uNMyt_RmUhqmzWFaBUMIun_PLX4c,
  seo_meta_58ZqtB_K1IuilVeNc7eYktW7x1RmlqK_4MdNhuJXZb8,
  marketing_tracking_P3cd25rIWXjymUzDBdWxKme6aT_iCSqmoAQdCd6CZgs,
  error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU,
  vue_datepicker_ZxbWd_XFJcMflv9_PwGgUvI_g6wpTHJLSRNAJm1TIzo,
  vue_google_tag_manager_client_zMJHJk_b5C9dZESreNMV3sEknkhrkVigOD8tWPDgeXw,
  plugin_client_lxWKvJVWQ7nxykTLpu__093p6rdFQhYd_ZKYo3UMtbc,
  ssg_detect_EZ9YuvzaydiaKxf5P50MldBhBrZB_zoyUvemA4ROKkU
]