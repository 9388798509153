export default defineNuxtRouteMiddleware(async () => {
  const isAuthenticated = useAuthState();
  const auth = useAuth();
  /**
   * Current auth state has not been initialized yet, so we do it once here.
   */
  if (auth.requestLastExecutedAt.value === undefined || isAuthenticated.value === undefined) {
    await auth.checkStatus();
  }
});
