// plugins/marketingTracking.ts
export default defineNuxtPlugin(() => {
  const marketingTracking = () => {
    if (import.meta.server) {
      return;
    }

    const { systemAPI } = useBackendRequests();
    const url = useRequestURL();
    const route = useRoute();
    const query = route.query;

    if (!query.utm_campaign && !query.mtm_campaign) {
      return;
    }

    const postData = { twClient: 1 };
    const getData: Record<string, string> = { landing: url.pathname.replace(/^\//, '') };

    const keys = ['mtm_campaign', 'mtm_kwd', 'mtm_source', 'mtm_medium', 'mtm_content', 'mtm_cid', 'mtm_group', 'mtm_placement'];

    const decodeValue = (value: string | null | undefined) => {
      if (!value) return '';
      return decodeURIComponent(value);
    };

    for (const key of keys) {
      if (query[key]) {
        getData[key] = decodeValue(query[key] as string);
      }
    }

    const utmToMtmMap: Record<string, string> = {
      utm_campaign: 'mtm_campaign',
      utm_source: 'mtm_source',
      utm_medium: 'mtm_medium',
      utm_content: 'mtm_content',
      utm_term: 'mtm_kwd',
    };

    for (const [utmKey, mtmKey] of Object.entries(utmToMtmMap)) {
      if (!query[mtmKey] && query[utmKey]) {
        getData[mtmKey] = decodeValue(query[utmKey] as string);
      }
    }

    let queryString = Object.keys(getData)
      .map((key) => `${key}=${getData[key]}`)
      .join('&');

    queryString = queryString.replace(/%2F/g, (match, offset, string) => {
      if (string.slice(0, offset).includes('landing')) {
        return '';
      }
      return '/';
    });

    systemAPI.sendMarketingTracking(queryString, postData);
  };
  marketingTracking();
});
