export default function () {
  const runtimeConfig = useRuntimeConfig();
  const backendUrl = runtimeConfig.public.backendUrl;
  return {
    agb: `${backendUrl}/agb`,
    faq: `${backendUrl}/faq`,
    kontakt: `${backendUrl}/kontakt`,
    support: `${backendUrl}/support`,
    subscription: `${backendUrl}/account/subscription`,
    billing: `${backendUrl}/account/billing`,
    successStory: `${backendUrl}/erfolgsgeschichten`,
    login: `${backendUrl}/login`,
    register: `${backendUrl}/registrieren`,
    willkommen: `${backendUrl}/willkommen`,
  };
}
