import validate from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__ioredis@5.6.0_mag_28160870fc8669f4f8b097b6722ee748/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45init_45auth_45state_45global from "/app/browser/middleware/01.init-auth-state.global.ts";
import _02_45init_45account_45state_45global from "/app/browser/middleware/02.init-account-state.global.ts";
import _03_45init_45populate_45stores_45global from "/app/browser/middleware/03.init-populate-stores.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__ioredis@5.6.0_mag_28160870fc8669f4f8b097b6722ee748/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45init_45auth_45state_45global,
  _02_45init_45account_45state_45global,
  _03_45init_45populate_45stores_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/browser/middleware/auth.ts")
}