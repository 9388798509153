export default defineNuxtPlugin(() => {
  function setSeoMeta(title?: string, description?: string) {
    const route = useRoute();
    const fullUrl = `https://tauschwohnung.com${route.fullPath}`;

    const seoMeta: { title?: string; description?: string; ogTitle?: string; ogDescription?: string; ogUrl: string } = {
      ogUrl: fullUrl,
    };

    if (title && title.trim() !== '') {
      seoMeta.title = title;
      seoMeta.ogTitle = title;
    }

    if (description && description.trim() !== '') {
      seoMeta.description = description;
      seoMeta.ogDescription = description;
    }

    useSeoMeta(seoMeta);
  }

  return {
    provide: {
      setSeoMeta,
    },
  };
});
