<script setup lang="ts">
import type { NuxtError } from '#app';

const props = defineProps({
  error: Object as () => NuxtError,
});

const handleError = () => clearError({ redirect: '/' });
</script>

<template>
  <nuxt-layout name="default">
    <tw-error-404 v-if="error?.statusCode === 404" />
    <tw-error-500 v-else :error="error" @error="handleError" />
  </nuxt-layout>
</template>
