import { normalizeTransferHousingProperty, normalizeTransferHousingPropertyGroup, normalizeTransferHousingType, normalizeTransferRoom } from '@/models/Raws';
import type { PropertyCategory } from '~/models/Raws';

export default defineNuxtRouteMiddleware(async () => {
  const isAuthenticated = useAuthState();
  const { accountAPI, housingAPI, chatAPI, systemAPI } = useBackendRequests();

  const shoutOutsStore = useShoutOutsStore();
  const baseStore = useBaseStore();
  const accountStore = useAccountStore();
  const chatStore = useChatStore();

  async function populateStores() {
    await initBaseStore();
    if (!isAuthenticated.value) {
      return;
    }

    const initializationTasks: Promise<void>[] = [];

    if (!baseStore.state.initializedAt) {
      initializationTasks.push(initBaseStore());
    }

    if (!baseStore.state.countOfUnseenConversations) {
      initializationTasks.push(initUnseenConversations());
    }

    if (!chatStore.initializedAt && accountStore.user?.guid) {
      initializationTasks.push(initChatResponseTimeAndRate(accountStore.user.guid));
      initializationTasks.push(initChatStatusCounts());
    }

    if (!shoutOutsStore.state.initializedAt) {
      initializationTasks.push(getActiveProducts());
    }

    if (!baseStore.state.featureList) {
      initializationTasks.push(getFeatureList());
    }

    try {
      await Promise.all(initializationTasks);
    } catch (error) {
      console.error('Error initializing stores:', error);
    }
  }

  async function initBaseStore() {
    const response = await housingAPI.getStaticFormData();
    const { rooms, housingTypes, housingPropertyGroups, housingProperties, housingPropertyGroupTypes } = response.data;
    baseStore.state.rooms = rooms.map(normalizeTransferRoom);
    baseStore.state.housingTypes = housingTypes.map(normalizeTransferHousingType);
    baseStore.state.properties = housingProperties.map(normalizeTransferHousingProperty);
    baseStore.state.housingPropertyGroups = housingPropertyGroups.map(normalizeTransferHousingPropertyGroup);
    baseStore.state.housingPropertyGroupTypes = housingPropertyGroupTypes as unknown as PropertyCategory[];
    baseStore.state.initializedAt = new Date();
  }

  async function initUnseenConversations() {
    try {
      const response = await chatAPI.getUnseenCount();
      const { count } = response.data;
      baseStore.state.countOfUnseenConversations = +count || 0;
    } catch (error) {
      console.error('Error in initUnseenConversations:', error);
    }
  }

  async function initChatResponseTimeAndRate(guid: string) {
    if(!guid) {
      console.error('Error in initChatResponseTimeAndRate: guid is not provided');
        return
    }
    try {
      const response = await chatAPI.getResponseTimeAndRate(guid);
        chatStore.responseRate.display = response.data.responseRate.display;
        chatStore.responseRate.rate = response.data.responseRate.rate;
        chatStore.responseTime = response.data.responseTime.display;
        chatStore.initializedAt = new Date();
    } catch (error) {
      console.error('Error in initUnseenConversations:', error);
    }
  }

  async function initChatStatusCounts() {
    try {
      await chatStore.getStatusCounts();
    } catch (error) {
      console.error('Error in getStatusCounts:', error);
    }
  }

  async function getActiveProducts() {
    try {
      const response = await accountAPI.getActiveProducts();
      const hasMultiPortalPublishing = response.some((product) => product.key === 'multi-portal-publishing');

      if (hasMultiPortalPublishing) {
        shoutOutsStore.state.multiPortal.isPurchased = true;
      }
      shoutOutsStore.state.initializedAt = new Date();
    } catch (error) {
      console.error('Error getting active products:', error);
    }
  }

  async function getFeatureList() {
    try {
      const response = await systemAPI.getFeatureList();
      baseStore.state.featureList = response || {};
    } catch (error) {
      console.error('Error getting feature list:', error);
    }
  }

  populateStores();
});
